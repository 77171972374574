import { noiseTexture } from "./add-noiseTexture-hubs.js";

const componentName = "spoke-force-cells"; // имя создаваемого компонента

const activationName = "applyForceCells"; // атрибут объекта в EventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  dmin: 2,
  dmax: 10,
  double: "false", // double-sided material if true
};

export function spokeForceCells(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.meshes = tHelper.getMeshesWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.meshes);

    const noise = noiseTexture.clone();
    noise.wrapS = THREE.RepeatWrapping;
    noise.wrapT = THREE.RepeatWrapping;

    for (let i = 0; i < this.meshes?.length; i++) {
      // const mat = this.meshes[i].material.clone();
      // mobileFixMaterial(mat);

      const mat = new THREE.ShaderMaterial();

      mat.alphaTest = 0.6;
      mat.depthWrite = false;
      mat.depthTest = true;
      mat.transparent = true;
      mat.blending = THREE.AdditiveBlending;

      if (this.params[i].double == "true") mat.side = THREE.DoubleSide;

      this.meshes[i].material = mat;
      patchBasic(this.meshes[i].material, this.params[i].color, this.params[i].dmin, this.params[i].dmax);
    }

    function patchBasic(mat, ucolor = defaultParams.color, dmin = defaultParams.dmin, dmax = defaultParams.dmax) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying vertex position to local object center 
        varying vec3 vpc;   // varying vertex position to camera
        varying vec3 vpw;   // varying vertex position to world
        varying vec3 vpz;   // varying object center to world
        varying vec3 vnl;   // varying normals to local object center
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
        varying float dist;
      `;

      const shaderVaryingsSet = `
        vuv = uv;
        vpl = position;
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;
        vpz = (modelMatrix * vec4(vec3(0.0), 1.0)).xyz;
        vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);
        vnl = normal;
        // vnc = normalize(normalMatrix * normal);
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        dist = length(vpz-cameraPosition);
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.dmin = { value: dmin };
        shader.uniforms.dmax = { value: dmax };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.noise = { value: noise };
      }

      const shaderUniforms = `
        uniform float time;
        uniform float dmin, dmax;
        uniform vec2 mouse;
        uniform vec3 ucolor;
        uniform sampler2D noise;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

        float hexDist(vec2 uv) {
                uv = abs(uv);
          float c = dot(uv,normalize(vec2(1.0,1.73)));
          return max(c,uv.x);
        }

        float hexGrid(vec2 uv, float size) {
          vec2  r = vec2(1.0,1.73);
                uv = uv/size;
          float stroke = 0.0+0.2
          *(sin(uv.x*0.3+sin(time*0.53)*5.0)*0.5+0.5)
          *(sin(uv.y*0.4+cos(time*0.72)*3.0)*0.5+0.5)
          ;
          float f1 = hexDist(mod(uv,r)-0.5*r);
                f1 = smoothstep(0.5-stroke,0.45-stroke,f1);
          float f2 = hexDist(mod(uv+r*0.5,r)-0.5*r);
                f2 = smoothstep(0.5-stroke,0.45-stroke,f2);
          return 1.0-max(f1,f2);
        }

        float f2slit ( float f, float lvl, float len, float smt ) { 
          return smoothstep(lvl-len*0.5-smt,lvl-len*0.5    ,f) - 
                smoothstep(lvl+len*0.5    ,lvl+len*0.5+smt,f); }
    
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}
    
            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
    
            ${basicFragmentMain}

            vec3  col = ucolor;  //outgoingLight;

            vec2  uv = vec2(1.0);

            uv = mix(uv, vpl.zy, abs(vnl).x);
            uv = mix(uv, vpl.xy, abs(vnl).z);
            uv = mix(uv, vpl.xz, abs(vnl).y);
    
            float scale = 1.0;
            float scaleNoise = 0.0117/scale;
            float scaleHex = 0.3*scale;
    
            float border = 5.0;
            float softness = 0.01;
    
            // float dmin = 2.0;
            // float dmax = 10.0;

            float level = smoothstep(dmax, dmin, dist);

                  // level = dist * mouse.x ;
    
            float radius = length(vpl.xy);
    
            float maskNoise = texture2D(noise, uv*scaleNoise*5.0).r *0.5 +
                            + texture2D(noise, uv*scaleNoise*3.0+time*0.01).r *0.5;

                  maskNoise = smoothstep(0.655, 0.264, maskNoise);
    
            float maskLevel = f2slit(maskNoise, level, 0.01,0.01);
                  maskNoise = smoothstep(level, level-0.01, maskNoise);
    
            float maskHex = hexGrid(uv, scaleHex) + 0.1;
                  maskHex *= maskNoise;
    
            float mask = clamp(maskHex + maskLevel, 0.0, 1.0);
    
            // vec3  locnor = normalize(vpw-vpz);
            // vec3  dircam = normalize(cameraPosition-vpz);
            // float d = dot(locnor, dircam);
            // col = vec3( 1.0 - step((mouse.x-0.5)*3.0, d) );
            // mask = 0.5;

            // col = abs(vnl);


            gl_FragColor = vec4(col, mask);	
    
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.meshes?.length; i++) {
      if (!this.meshes[i].uniforms) {
        this.meshes[i].uniforms =
          this.meshes[i].material?.userData?.shader?.uniforms ||
          this.meshes[i].children[0]?.material?.userData?.shader?.uniforms;
        return;
      }
      this.meshes[i].uniforms.time.value = performance.now() / 1000;
      // this.meshes[i].uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
