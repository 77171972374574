const componentName = "spoke-obj-skybox"; // имя создаваемого компонента

const activationName = "applyObjSkybox"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  freq: 0.0,
  rad: 1,
  size: 1,
  rotx: 0.0,
  roty: 0.0,
  rotz: 0.0,
  texname: "untitled",
  pulse: 1.0,
  add: "false", // enable additive blending
};

export function spokeObjSkybox(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    this.texobj = [];

    for (let i = 0; i < this.objects?.length; i++) {
      const spokeName = this.params[i].texname || defaultParams.texname;
      this.texobj[i] = document.querySelector(`#environment-el .${spokeName}`)?.object3D;
      // console.log("TEXOBJ", this.texobj[i]);

      const rad = this.params[i].rad || defaultParams.rad;
      const size = this.params[i].size || defaultParams.size;

      const ringmesh = new THREE.Mesh(
        new THREE.SphereGeometry(rad * 0.5, 16, 12),
        patchRing(
          new THREE.MeshBasicMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            blending: this.params[i].add == "true" ? THREE.AdditiveBlending : THREE.NormalBlending,
            side: THREE.BackSide,
          }),
          this.params[i].color,
          this.params[i].freq,
          this.params[i].pulse,
        ),
      );

      if (this.params[i].order) {
        ringmesh.renderOrder = this.params[i].order;
      }

      this.objects[i].parent.el.removeObject3D("object3d");
      this.objects[i].el.setObject3D("mesh", ringmesh);
      // this.objects[i].el.object3D.children[0].rotation.reorder("YXZ");
    }

    function patchRing(mat, ucolor = defaultParams.color, freq = defaultParams.freq, pulse = defaultParams.pulse) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        // vnc = normalize(normalMatrix * normal);  
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.pulse = { value: pulse };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.tex = { value: 0 };
      }

      const shaderUniforms = `
        uniform float time, freq, pulse;
        uniform vec2 mouse;
        uniform vec3 ucolor;
        uniform sampler2D tex;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
                
        vec4  draw() {

          vec4  tex = texture2D(tex, fract(vuv+vec2(time*freq,0.0)));

          vec3  col = tex.rgb;
                col *= 1.0 + 0.75 - (cos(time*pulse)*0.75);

          float alpha = tex.a;

          return vec4(col, alpha);	

        }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log("Input Material Data:", [shader, shader.vertexShader, shader.fragmentShader]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
          
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        ${basicFragmentDef}
  
        void main() {

          vec4  draw = draw();

          #include <clipping_planes_fragment>
          vec4 diffuseColor = vec4( diffuse, opacity );
          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <specularmap_fragment>
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          #ifdef USE_LIGHTMAP
            vec4 lightMapTexel = texture2D( lightMap, vUv2 );
            reflectedLight.indirectDiffuse += lightMapTexel.rgb * lightMapIntensity * RECIPROCAL_PI;
          #else
            reflectedLight.indirectDiffuse += vec3( 1.0 );
          #endif
          #include <aomap_fragment>
          reflectedLight.indirectDiffuse *= diffuseColor.rgb;

          vec3 outgoingLight = draw.rgb;

          #include <envmap_fragment>
          #include <output_fragment>
          #include <tonemapping_fragment>
          #include <encodings_fragment>
          #include <fog_fragment>
          #include <premultiplied_alpha_fragment>
          #include <dithering_fragment>

          gl_FragColor.a = draw.a;
        
        }
  
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
        mat.needsUpdate = true;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      target.el.object3D.children[0].rotation.x += 0.01 * this.params[i].rotx || defaultParams.rotx;
      target.el.object3D.children[0].rotation.y += 0.01 * this.params[i].roty || defaultParams.roty;
      target.el.object3D.children[0].rotation.z += 0.01 * this.params[i].rotz || defaultParams.rotz;
      // target.el.object3D.children[0].rotation._order = "YXZ";
      // target.el.object3D.children[0].rotation.reorder("YXZ");
      // console.log(target.el.object3D.children[0].rotation);
      target.el.object3D.children[0].updateMatrix();

      if (!target.el.object3D?.children[0]?.material?.userData?.shader?.uniforms) {
        return;
      }

      target.el.object3D.children[0].material.userData.shader.uniforms.time.value = performance.now() / 1000;
      // target.el.object3D.children[0].material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];

      if (
        this.texobj[i]?.children[1]?.material?.map &&
        target.el.object3D.children[0].material.userData.shader.uniforms.tex.value == 0
      ) {
        target.el.object3D.children[0].material.userData.shader.uniforms.tex.value =
          this.texobj[i].children[1].material.map;
      }
    }
  },
});
