const componentName = "spoke-obj-spinner"; // имя создаваемого компонента

const activationName = "applyObjSpinner"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white", // main color
  flash: "white", // flashing color
  amp: 0.0, // Z amplitude
  shift: 0.5, // Z shift speed
  rmax: 1.0, // max radius of ring
  rmin: 1.0, // min radius of ring
  num: 0.0, // number of stripes
  fat: 0.25, // fatness of stripe
  pulse: 0.0, // speed of size changing
  spin: 0.0, // spinning speed
  speed: 0.0, // rotation speed
  freq: 0.0, // frequency of flashing
  len: 0.5, // length of a segment
  alpha: 1.0, // alpha
  rot: 0.0, // X-axis rotation speed
  hide: "true", // remove target-object
  add: "true", // set additive blending
};

export function spokeObjSpinner(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    // const noise = noiseTexture.clone();
    // noise.wrapS = THREE.RepeatWrapping;
    // noise.wrapT = THREE.RepeatWrapping;

    for (let i = 0; i < this.objects?.length; i++) {
      this.mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(1, 1), //.rotateX(Math.PI / 2),
        patchBasic(
          new THREE.ShaderMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            side: THREE.DoubleSide,
            blending: this.params[i].add == "false" ? THREE.NormalBlending : THREE.AdditiveBlending,
          }),
          this.params[i].color,
          this.params[i].flash,
          this.params[i].amp,
          this.params[i].shift,
          this.params[i].rmax,
          this.params[i].rmin,
          this.params[i].num,
          this.params[i].fat,
          this.params[i].pulse,
          this.params[i].spin,
          this.params[i].speed,
          this.params[i].freq,
          this.params[i].len,
          this.params[i].alpha,
          this.params[i].rot,
        ),
      );

      // const quat = new THREE.Quaternion();
      // this.objects[i].getWorldQuaternion(quat);
      // this.mesh.setRotationFromQuaternion(quat.clone().invert());

      if (this.params[i].hide != "false") {
        this.objects[i].parent.el.removeObject3D("object3d");
      }

      this.objects[i].el.setObject3D("mesh", this.mesh);
    }

    function patchBasic(
      mat,
      ucolor = defaultParams.color,
      flash = defaultParams.flash,
      amp = defaultParams.amp,
      shift = defaultParams.shift,
      rmax = defaultParams.rmax,
      rmin = defaultParams.rmin,
      num = defaultParams.num,
      fat = defaultParams.fat,
      pulse = defaultParams.pulse,
      spin = defaultParams.spin,
      speed = defaultParams.speed,
      freq = defaultParams.freq,
      len = defaultParams.len,
      alpha = defaultParams.alpha,
      rot = defaultParams.rot,
    ) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        vnc = normalize(normalMatrix * normal);  
        vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.flash = { value: new THREE.Color(flash) };
        shader.uniforms.amp = { value: amp };
        shader.uniforms.shift = { value: shift };
        shader.uniforms.rmax = { value: rmax };
        shader.uniforms.rmin = { value: rmin };
        shader.uniforms.num = { value: num };
        shader.uniforms.fat = { value: fat };
        shader.uniforms.pulse = { value: pulse };
        shader.uniforms.spin = { value: spin };
        shader.uniforms.speed = { value: speed };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.len = { value: len };
        shader.uniforms.alpha = { value: alpha };
        shader.uniforms.rotx = { value: rot };
        // shader.uniforms.noise = { value: noise };
      }

      const shaderUniforms = `
        uniform float time;
        uniform float amp,shift,rmax,rmin,num,fat,pulse,spin,speed,freq,len,alpha,rotx;
        uniform vec2 mouse;
        uniform vec3 ucolor, flash;
        // uniform sampler2D noise;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

        /* Cartesian to Polar */
        vec2 xy2md( vec2 xy ) {
          return vec2( 
            sqrt( pow(xy.x,2.0) + pow(xy.y,2.0) ) ,
            atan(xy.y,xy.x) ); }
        
        /* Polar to Cartesian */
        vec2 md2xy( vec2 md ) {
          return vec2( 
            md.x * cos(md.y) ,
            md.x * sin(md.y) ); }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            // float amp = 1.0;
            //       amp *= 0.5;
            // float shift = 1.3;

            vec3  pos = position;
                  pos.z += 0.5*amp * sin((time-ONE_PI*0.5)*shift);

                  pos.yz *= rot(time*rotx);

            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(pos, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
    
            ${basicFragmentMain}

            vec2  uv = vuv - 0.5;
            float base = 0.5;
            float soft = 0.01;

            // float rmax = 1.0;
            // float rmin = 0.5;

            // float num = 3.0;
            // float fat = 0.1;
            // float pulse = 1.7;
            // float spin = 0.5;
            // float speed = 0.5;
            // float freq = 1.0;
            // float len = 0.85;
            // float alpha = 1.0;

            vec3  col = mix(flash, ucolor, cos(time*freq*TWO_PI)*0.5+0.5);

            float fatbase = fat*base;
                  base *= mix(rmin, rmax, cos(time*pulse)*0.5+0.5);

            float dist = length(uv);
            float ring = 1.0;
                  ring *= smoothstep(base, base-soft, dist);
                  ring *= smoothstep(base-fatbase-soft, base-fatbase, dist);

            vec2  uvr = xy2md(uv);

            if (num > 0.0) {
              float segments = fract( (uvr.y - 2.0*sin(time*spin) - time*speed) / TWO_PI * num );
              float lines = smoothstep(len, len-soft*num, segments) * smoothstep(0.0, soft*num, segments);
              ring *= lines;
            }

    
            gl_FragColor = vec4(col, alpha * ring);	

            // #include <tonemapping_fragment>
            // #include <encodings_fragment>
          
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      if (!target.uniforms) {
        target.uniforms =
          target.el.getObject3D("mesh")?.material?.userData?.shader?.uniforms ||
          target.el.getObject3D("mesh")?.children[0]?.material?.userData?.shader?.uniforms;
        return;
      }

      target.uniforms.time.value = performance.now() / 1000;
      // target.uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
