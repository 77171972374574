const componentName = "spoke-obj-waver"; // имя создаваемого компонента

const activationName = "applyObjWaver"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white", // main color
  glow: "white", // glowing color
  density: 1.0,
  speed: 1.0,
  fade: 1.0,
  num: 10.0,
  wavesize: 1.0,
  soft: 0.3,
  ringsize: 0.15,
  light: 1.0,
};

export function spokeObjWaver(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    // const noise = noiseTexture.clone();
    // noise.wrapS = THREE.RepeatWrapping;
    // noise.wrapT = THREE.RepeatWrapping;

    for (let i = 0; i < this.objects?.length; i++) {
      this.mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(1, 1), //.rotateX(Math.PI / 2),
        patchBasic(
          new THREE.MeshBasicMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            // side: THREE.DoubleSide,
            // blending: this.params[i].add == "false" ? THREE.NormalBlending : THREE.AdditiveBlending,
          }),
          this.params[i].color,
          this.params[i].glow,
          this.params[i].density,
          this.params[i].speed,
          this.params[i].fade,
          this.params[i].num,
          this.params[i].wavesize,
          this.params[i].soft,
          this.params[i].ringsize,
          this.params[i].light,
        ),
      );

      // const quat = new THREE.Quaternion();
      // this.objects[i].getWorldQuaternion(quat);
      // this.mesh.setRotationFromQuaternion(quat.clone().invert());

      this.objects[i].parent.el.removeObject3D("object3d");
      this.objects[i].el.setObject3D("mesh", this.mesh);
    }

    function patchBasic(
      mat,
      ucolor = defaultParams.color,
      glow = defaultParams.glow,
      density = defaultParams.density,
      speed = defaultParams.speed,
      fade = defaultParams.fade,
      num = defaultParams.num,
      wavesize = defaultParams.wavesize,
      soft = defaultParams.soft,
      ringsize = defaultParams.ringsize,
      light = defaultParams.light,
    ) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        // vnc = normalize(normalMatrix * normal);  
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.glow = { value: new THREE.Color(glow) };
        shader.uniforms.density = { value: density };
        shader.uniforms.speed = { value: speed };
        shader.uniforms.fade = { value: fade };
        shader.uniforms.num = { value: num };
        shader.uniforms.wavesize = { value: wavesize };
        shader.uniforms.soft = { value: soft };
        shader.uniforms.ringsize = { value: ringsize };
        shader.uniforms.ulight = { value: light };

        // shader.uniforms.noise = { value: noise };
      }

      const shaderUniforms = `
        uniform float time;
        uniform float density, speed, fade, num, wavesize, soft, ringsize, ulight;
        uniform vec2 mouse;
        uniform vec3 ucolor, glow;
        // uniform sampler2D noise;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

        float hexDist(vec2 uv) {
                uv = abs(uv);
          float c = dot(uv,normalize(vec2(1.0,1.73)));
          return max(c,uv.x);
        }

        float hexGrid(vec2 uv, float size, float stroke) {
          vec2  r = vec2(1.0,1.73);
                uv = uv/size;

          float f1 = hexDist(mod(uv,r)-0.5*r);
                f1 = smoothstep(0.5-stroke,0.45-stroke,f1);
          float f2 = hexDist(mod(uv+r*0.5,r)-0.5*r);
                f2 = smoothstep(0.5-stroke,0.45-stroke,f2);
          return 1.0-max(f1,f2);
        }

        vec4  draw() {

          vec2  uv = vuv - 0.5;
          float dist = length(uv);

          float dmin = 0.05;
          float dmax = 0.50;

          vec3  col = ucolor;

          float rings = sin(dist * TWO_PI * num * density * 2.0) *0.5+0.5;
                rings += rings + dist*2.0 + smoothstep( 1.0 - ringsize * density, 1.0, rings);

          float temp = time * speed * 2.0;

          float wave = sin(dist * TWO_PI * 2.0 * wavesize - temp) *0.5+0.5;

          float fadeout = pow(1.0 - 2.0 * abs(uv.y), 2.0) - smoothstep(dmin, dmax, dist) * 0.5;
                fadeout = mix(1.0, fadeout, fade);
          
                rings *= wave * fadeout;
                col = mix(glow, col, rings);

          float mask = smoothstep(dmax, dmax-0.01, dist) * smoothstep(dmin-0.01, dmin, dist);

          float scaleHex = 0.1*0.05;
          float maskHex = 1.0-hexGrid(vuv, scaleHex, pow(1.0-rings,1.0)*0.25);
                mask *= maskHex;
  
          float alpha = mask * rings;
                col *= ulight;

                // dist fix for 2heads
                col = mix(glow, col, dist*2.0);
                alpha *= dist;

          return vec4(col, alpha);	

        }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            // gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
  
            // $ {basicFragmentMain}
  
            vec4  draw = draw();
  
            #include <clipping_planes_fragment>
            vec4 diffuseColor = vec4( diffuse, opacity );
            #include <logdepthbuf_fragment>
            #include <map_fragment>
            #include <color_fragment>
            #include <alphamap_fragment>
            #include <alphatest_fragment>
            #include <specularmap_fragment>
            ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
            #ifdef USE_LIGHTMAP
              vec4 lightMapTexel = texture2D( lightMap, vUv2 );
              reflectedLight.indirectDiffuse += lightMapTexel.rgb * lightMapIntensity * RECIPROCAL_PI;
            #else
              reflectedLight.indirectDiffuse += vec3( 1.0 );
            #endif
            #include <aomap_fragment>
            reflectedLight.indirectDiffuse *= diffuseColor.rgb;
  
            vec3 outgoingLight = draw.rgb; // reflectedLight.indirectDiffuse;
  
            #include <envmap_fragment>
            #include <output_fragment>
            #include <tonemapping_fragment>
            #include <encodings_fragment>
            #include <fog_fragment>
            #include <premultiplied_alpha_fragment>
            #include <dithering_fragment>
  
            gl_FragColor.a = draw.a;
          
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      if (!target.el.object3D?.children[0]?.material?.userData?.shader?.uniforms) {
        return;
      }

      target.el.object3D.children[0].material.userData.shader.uniforms.time.value = performance.now() / 1000;
      // target.el.object3D.children[0].material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
