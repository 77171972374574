import "aframe";
import { TYPE } from "three-ammo/constants";
import { COLLISION_LAYERS } from "../../../constants";

export const COLLIDABLE_MODEL_INIT_EVENT = "event-collidable-load";

AFRAME.GLTFModelPlus.registerComponent("collidable-model", "collidable-model", (el, _componentName, componentData) => {
  if (!componentData.eventId || !componentData.objectId) {
    console.error("collidable-model can not get data", componentData);
    return;
  }

  /**
   * Add mesh
   */

  const sizeCollider = new THREE.Vector3(2, 2, 2);
  const scale = new THREE.Vector3(1, 1, 1);

  /**
   * Создаем меш коллайдера по размерам объекта, меш коллайдера при этом будет поворачиваться и
   * скалироваться параметрами объекта, поэтому наш меш, нужно развернуть в другую сторону и пропорционально уменьшить
   */
  if (el.object3D) {
    const box3 = new THREE.Box3();
    const objectQuaternion = new THREE.Quaternion();
    el.object3D.getWorldQuaternion(objectQuaternion);
    el.object3D.getWorldScale(scale);
    objectQuaternion.invert();
    const cloneMesh = el.object3D.clone();
    cloneMesh.applyQuaternion(objectQuaternion);
    box3.setFromObject(cloneMesh);
    box3.getSize(sizeCollider);
    //Добавляем константу
    const offset = new THREE.Vector3(1, 1, 1);
    offset.multiplyScalar(0.7);
    sizeCollider.add(offset);
    sizeCollider.divide(scale);
  }

  const radius = Math.max(sizeCollider.x, sizeCollider.z) * 0.5;
  const mesh = new THREE.Mesh(
    new THREE.CylinderBufferGeometry(radius, radius, sizeCollider.y),
    new THREE.MeshBasicMaterial({
      visible: false,
      depthWrite: false,
    }),
  );
  mesh.visible = false;

  Object.assign(mesh.userData, {
    eventId: componentData.eventId,
    objectId: componentData.objectId,
  });

  /**
   * Добавляем меш, так как коллизии вычисляются именно по геометрии мешей
   */
  el.setObject3D("mesh", mesh);

  /**
   * Боди хелпер нужен, чтобы афрейм обрабатывал коллизии
   */
  el.setAttribute("body-helper", {
    mass: 0,
    type: TYPE.STATIC,
    collisionFilterGroup: COLLISION_LAYERS.INTERACTABLES,
    collisionFilterMask: COLLISION_LAYERS.DEFAULT_SPAWNER,
  });

  dispatchEvent(new CustomEvent(COLLIDABLE_MODEL_INIT_EVENT, { detail: { mesh } }));
  /**
   * Подключаем афрейм-компонент
   */
  // el.setAttribute("collidable-model", {});
});
