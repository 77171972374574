const componentName = "spoke-obj-sphere-cells"; // имя создаваемого компонента

const activationName = "applyObjSphereCells"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  freq: 0.0,
  rad: 1,
  size: 1,
  rotx: 0.0,
  roty: 0.0,
  rotz: 0.0,
};

export function spokeObjSphereCells(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    for (let i = 0; i < this.objects?.length; i++) {
      const ringmesh = new THREE.Mesh(
        new THREE.SphereGeometry(0.5, 16, 12),
        // new THREE.MeshBasicMaterial()
        patchRing(
          new THREE.ShaderMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            // blending: THREE.AdditiveBlending,
            side: THREE.DoubleSide,
          }),
          this.params[i].color,
          this.params[i].freq,
        ),
      );

      this.objects[i].parent.el.removeObject3D("object3d");
      this.objects[i].el.setObject3D("mesh", ringmesh);
      // this.objects[i].el.object3D.children[0].rotation.reorder("YXZ");
    }

    function patchRing(mat, ucolor = defaultParams.color, freq = defaultParams.freq) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        // vnc = normalize(normalMatrix * normal);  
        vps = projectionMatrix * modelViewMatrix * vec4( 0.0,0.0,0.0, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.tex = { value: 0 };
      }

      const shaderUniforms = `
        uniform float time, freq;
        uniform vec2 mouse;
        uniform vec3 ucolor;
        uniform sampler2D tex;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
          
        float hexDist(vec2 uv) {
                uv = abs(uv);
          float c = dot(uv,normalize(vec2(1.0,1.73)));
          return max(c,uv.x);
        }

        float hexGrid(vec2 uv, float size, float stroke) {
          vec2  r = vec2(1.0,1.73);
                uv = uv/size;

          float f1 = hexDist(mod(uv,r)-0.5*r);
                f1 = smoothstep(0.5-stroke,0.45-stroke,f1);
          float f2 = hexDist(mod(uv+r*0.5,r)-0.5*r);
                f2 = smoothstep(0.5-stroke,0.45-stroke,f2);
          return 1.0-max(f1,f2);
        }


      `;

      mat.onBeforeCompile = shader => {
        // console.log("Input Material Data:", [shader, shader.vertexShader, shader.fragmentShader]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
          
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        ${basicFragmentDef}
  
        void main() {

          vec3  col = vec3(255.0,165.0,0.0)/255.0 * 0.65;
          vec3  incol = vec3(72.0,0.0,189.0)/255.0 * 0.8;

                float dmin = 04.0;
                float dmax = 25.0;

          float dist = smoothstep(dmax, dmin, vps.w);

                col = mix(col, incol, dist);

          float grid = 1.0-hexGrid(vuv + vec2(-time*0.015, 0.0), 0.020, 0.025 + 0.25 * (sin(vpl.y*15.0-time*3.0)*0.5+0.5) );

                col *= mix(0.7+0.3*grid, 0.5+0.5*grid, dist);

          float alpha = smoothstep(-0.25, 0.25, vpl.y);

          gl_FragColor = vec4(col, alpha);	

        
        }
  
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
        mat.needsUpdate = true;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      target.el.object3D.children[0].rotation.x += 0.01 * this.params[i].rotx || defaultParams.rotx;
      target.el.object3D.children[0].rotation.y += 0.01 * this.params[i].roty || defaultParams.roty;
      target.el.object3D.children[0].rotation.z += 0.01 * this.params[i].rotz || defaultParams.rotz;

      target.el.object3D.children[0].updateMatrix();

      if (!target.el.object3D?.children[0]?.material?.userData?.shader?.uniforms) {
        return;
      }

      target.el.object3D.children[0].material.userData.shader.uniforms.time.value = performance.now() / 1000;
      // target.el.object3D.children[0].material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];
    }
    APP.scene.systems["fx-system"].changeBloom();
  },
});
