import tex01 from "./assets/skybox-01.jpg";
import tex02 from "./assets/skybox-02.jpg";
import tex03 from "./assets/skybox-03.jpg";
import tex04 from "./assets/skybox-04.jpg";
import tex05 from "./assets/skybox-05.jpg";
import tex06 from "./assets/skybox-06.jpg";

const tex = [null, tex01, tex02, tex03, tex04, tex05, tex06];

export function skyboxTexture(num) {
  return new THREE.TextureLoader().load(tex[num]);
}
