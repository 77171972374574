const componentName = "spoke-area-glow"; // имя создаваемого компонента

const activationName = "applyAreaGlow"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  size: 1.0,
  freq: 0.0,
  core: 1.0,
  glow: 0.1,
  hide: "false", // hide target object
  add: "false", // enable additive blending
};

export function spokeAreaGlow(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    for (let i = 0; i < this.objects?.length; i++) {
      const size = this.params[i].size || defaultParams.size;

      this.mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(size * 10.0, size * 10.0),
        patchBasic(
          new THREE.ShaderMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            // side: THREE.DoubleSide,
            blending: this.params[i].add == "true" ? THREE.AdditiveBlending : THREE.NormalBlending,
          }),
          this.params[i].color,
          this.params[i].freq,
          this.params[i].core,
          this.params[i].glow,
        ),
      );

      const quat = new THREE.Quaternion();
      this.objects[i].getWorldQuaternion(quat);
      this.mesh.setRotationFromQuaternion(quat.clone().invert());
      // this.mesh.updateMatrix();

      if (this.params[i].hide === "true") {
        this.objects[i].parent.el.removeObject3D("object3d");
        // this.objects[i].parent.traverse(o => {
        //   if (o.type === "Mesh") {
        //     o.material.visible = false;
        //   }
        // });
      }

      this.objects[i].el.setObject3D("mesh", this.mesh);
    }

    function patchBasic(
      mat,
      ucolor = defaultParams.color,
      freq = defaultParams.freq,
      core = defaultParams.core,
      glow = defaultParams.glow,
    ) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
        // varying float phase;
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        // vnc = normalize(normalMatrix * normal);  
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.amount = { value: 0 };
        shader.uniforms.time = { value: 0 };
        shader.uniforms.core = { value: core };
        shader.uniforms.glow = { value: glow };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
      }

      const shaderUniforms = `
        uniform float time, amount, freq, core, glow;
        uniform vec2 mouse;
        uniform vec3 ucolor;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
    
        mat4 rotation3d(vec3 axis, float angle) {
          axis = normalize(axis);
          float s = sin(angle);
          float c = cos(angle);
          float oc = 1.0 - c;
          return mat4(
            oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
            oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
            oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
            0.0,                                0.0,                                0.0,                                1.0
          ); }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}
    
            mat4  MatrixLocalToWorld = modelMatrix;

            mat3  MatrixWorldToLocal = mat3(transpose(inverse(modelMatrix)));
    
            vec3  LocalPosition = position;
    
            vec3  WorldCameraPosition = cameraPosition;
    
            vec3  WorldObjectCenter = ( MatrixLocalToWorld * vec4(0.0,0.0,0.0,1.0) ).xyz;
    
            vec3  WorldObjectToCamera = ( WorldCameraPosition - WorldObjectCenter );
    
            vec3  WorldObjectToCameraDirection = normalize( WorldObjectToCamera );
    
    // --- rotation X
    
            vec3  LocalDirectionLeft = vec3(-1.0, 0.0, 0.0);
    
            vec3  WorldDirectionLeft = LocalDirectionLeft * MatrixWorldToLocal ;
    
            float RotationAngleLeft = WorldObjectToCameraDirection.y 
            * ONE_PI*0.5 - 0.25*WorldObjectToCameraDirection.y;
    
            LocalPosition = (vec4(LocalPosition,1.0) 
            * rotation3d( WorldDirectionLeft, RotationAngleLeft ) ).xyz;
    
    // --- rotation Y
    
            vec3  LocalDirectionUp = vec3(0.0,1.0,0.0);
    
            vec3  WorldDirectionUp = LocalDirectionUp * MatrixWorldToLocal;
    
            float RotationAngleUp = - atan(WorldObjectToCamera.z, WorldObjectToCamera.x)
            + 0.5*ONE_PI;
    
            LocalPosition = (vec4(LocalPosition,1.0) 
            * rotation3d( WorldDirectionUp, RotationAngleUp ) ).xyz;
    
    // --- output
    
            gl_Position = projectionMatrix * viewMatrix * modelMatrix 
            * vec4(LocalPosition, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
    
            ${basicFragmentMain}
    
            vec3  col = ucolor;

            // float dist = dot(vuv-0.5,vuv-0.5);

            float dist = length(vuv-0.5);

            float alpha = 0.02*core/dist;

            float mask = smoothstep(glow,0.0,dist);

            alpha *= cos(time*freq*TWO_PI)*0.5+0.5;

            gl_FragColor = vec4(col, alpha * mask);	
          
    
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      if (!target.uniforms) {
        target.uniforms =
          target.el.getObject3D("mesh")?.material?.userData?.shader?.uniforms ||
          target.el.getObject3D("mesh")?.children[0]?.material?.userData?.shader?.uniforms;
        return;
      }

      target.uniforms.time.value = performance.now() / 1000;
      // target.uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
