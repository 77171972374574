/* global APP*/
import { getReticulumFetchUrl, hubUrl } from "./utils/phoenix-utils";
import { updateEnvironmentForHub, getSceneUrlForHub, updateUIForHub, remountUI } from "./hub";
import { onHubChange } from "./custom/hub/mic";
import { AElement } from "aframe";
import { getNextHub } from "./utils/get-next-hub";
import { disableMovement, enableMovement } from "./custom/utils/movement";

function unloadRoomObjects() {
  document.querySelectorAll("[pinnable]").forEach((el: AElement) => {
    if (el.components.pinnable.data.pinned) {
      el.parentNode?.removeChild(el);
    }
  });

  // Clean up empty object.gltf nodes
  document.querySelectorAll("#objects-scene .Room_Objects").forEach((el: AElement) => {
    if (!el.children.length) {
      el.parentNode?.parentNode?.removeChild(el.parentNode);
    }
  });
}

function loadRoomObjects(hubId: string) {
  const objectsScene = document.querySelector("#objects-scene");
  const objectsUrl = getReticulumFetchUrl(`/${hubId}/objects.gltf`);
  const objectsEl = document.createElement("a-entity") as AElement;
  objectsEl.setAttribute("gltf-model-plus", { src: objectsUrl, useCache: false, inflate: true });
  objectsScene?.appendChild(objectsEl);
}

/**
 * Не стоит этот метод выносить в зависимости, так как это ломает сторибук.
 * Приходится юзать глобально.
 */
async function changeHub(props: changeHubProps) {
  const {
    id,
    slug,
    addToHistory = false,
    // TODO Вообще есть настройка APP.store.state.preferences.fastRoomSwitching,
    // но она хранится на стороне браузера.
    // Надо как-то продумать хранение этой настройки на стороне сервера
    // и невозможность переопределения на стороне браузера
    fastRoomSwitching = false,
    usePreloader = false,
  } = props || {};

  disableMovement();

  if (usePreloader) {
    remountUI({
      showPreload: true,
      nextHubName: slug,
    });
  }

  return await (async () => {
    if (
      !APP ||
      !APP.hubChannel ||
      !APP.dialog ||
      // @ts-expect-error types
      !NAF.connection.adapter
    ) {
      return;
    }

    let hubId = id;

    if (!hubId && slug) {
      hubId = await getNextHub(slug).catch(error => {
        console.error(error);
        return undefined;
      });
    }

    if (!hubId) {
      console.error("Can not get hubId");
      return;
    }

    if (hubId === APP.hub?.hub_id) {
      console.info("Change hub called with the current hub id. This is a noop.");
      return;
    }

    /**
     * Если не используются быстрые переходы,
     * делаем переход в хаб перезагрузкой страницы
     */
    if (!fastRoomSwitching) {
      const url = hubUrl(hubId, {});

      if (url.href) {
        url.searchParams.set("autologin", "true");
        await new Promise(() => window.location.assign(url.href)).finally();
        return;
      }
    }

    // Suppress on-screen join and leave messages until we receive a sync.
    APP.hideHubPresenceEvents = true;
    const scene = AFRAME.scenes[0];

    let data;
    try {
      data = await APP.hubChannel.migrateToHub(hubId);
    } catch (e) {
      console.warn(`Failed to join hub ${hubId}: ${e.reason}|${e.message}`);
      APP.messageDispatch?.log("joinFailed", { message: e.message });
      return;
    }

    const hub = data.hubs[0];

    if (addToHistory) {
      /**
       * pushState как бы создает отдельную ветку в истории, что негативно
       * влияет на механики перехода по стрелкам в браузере и
       * обновление страницы (пользователя перекидывает на старую страницу, а не обновляет текущую)
       */
      window.history.pushState(null, "", hubUrl(hubId, {}, hub.slug));
    }

    APP.hub = hub;
    updateUIForHub(hub, APP.hubChannel);
    // @ts-expect-error types
    scene.emit("hub_updated", { hub });

    APP.subscriptions?.setSubscribed(data.subscriptions.web_push);

    remountUI({
      hubIsBound: data.hub_requires_oauth,
      initialIsFavorited: data.subscriptions.favorites,
      showPreload: true,
      // TODO Сейчас передается id или slug и на уровне шаблона идет преобразование вывода
      // Надо доработать мемтод
      nextHubName: hub.name,
    });

    // @ts-expect-error types
    NAF.entities.removeRemoteEntities();
    // @ts-expect-error types
    await NAF.connection.adapter.disconnect();
    await APP.dialog.disconnect();
    unloadRoomObjects();
    // @ts-expect-error types
    NAF.connection.connectedClients = {};
    // @ts-expect-error types
    NAF.connection.activeDataChannels = {};

    // @ts-expect-error types
    NAF.room = hub.hub_id;

    const gltfModelPlusEl = document.querySelector("#environment-scene")?.childNodes[0] as AElement | null;
    const viewingCameraEl = document.getElementById("viewing-camera") as AElement | null;

    if (gltfModelPlusEl?.components["gltf-model-plus"].data.src !== (await getSceneUrlForHub(hub))) {
      const fader = viewingCameraEl?.components["fader"];
      fader.fadeOut().then(() => {
        // @ts-expect-error types
        scene.emit("reset_scene");
        updateEnvironmentForHub(hub, APP.entryManager);
      });
    }

    APP.retChannel?.push("change_hub", { hub_id: hub.hub_id });

    await Promise.all([
      APP.dialog.connect({
        serverUrl: `wss://${hub.host}:${hub.port}`,
        roomId: hub.hub_id,
        serverParams: { host: hub.host, port: hub.port, turn: hub.turn },
        scene,
        clientId: APP.dialog._clientId,
        forceTcp: APP.dialog._forceTcp,
        forceTurn: APP.dialog._forceTurn,
        iceTransportPolicy: APP.dialog._iceTransportPolicy,
      }),

      // @ts-expect-error types
      NAF.connection.adapter.connect(),
    ]);

    loadRoomObjects(hubId);

    APP.hubChannel.sendEnteredEvent();

    APP.messageDispatch?.receive({
      type: "hub_changed",
      hubName: hub.name,
      showLineBreak: true,
    });

    onHubChange();
  })().finally(() => {
    enableMovement();

    remountUI({
      showPreload: false,
    });
  });
}

global.changeHub = changeHub;

// TODO see if there is a better way to do this with react router
window.addEventListener("popstate", function () {
  if (!APP.store.state.preferences.fastRoomSwitching) return;
  const qs = new URLSearchParams(location.search);
  const newHubId = qs.get("hub_id") || document.location.pathname.substring(1).split("/")[0];
  if (newHubId && newHubId !== APP.hub?.hub_id) {
    changeHub({
      id: newHubId,
      addToHistory: false,
    });
  }
});
