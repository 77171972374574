import { randomTexture } from "./add-randomTexture-hubs.js";

const componentName = "spoke-media-hologram"; // имя создаваемого компонента

const activationName = "applyMediaHologram"; // атрибут объекта в EventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  tint: 1,
  dmin: 7,
  dmax: 15,
  name: "untitled",
  billboard: "false",
};

export function spokeMediaHologram(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    this.unpatched = [];
    this.patched = [];

    this.random = randomTexture.clone();
    this.random.wrapS = THREE.RepeatWrapping;
    this.random.wrapT = THREE.RepeatWrapping;
    this.random.minFilter = THREE.NearestFilter;
    // this.random.magFilter = THREE.NearestFilter;

    for (let i = 0; i < this.objects?.length; i++) {
      this.unpatched[i] = tHelper.getObjectsWithName(this.params[i].name || defaultParams.name);
      this.patched[i] = [];

      this.objects[i].parent.el.removeObject3D("object3d");
    }
  },

  patch: function (
    mat,
    ucolor = defaultParams.color,
    tint = defaultParams.tint,
    dmin = defaultParams.dmin,
    dmax = defaultParams.dmax,
    billboard = defaultParams.billboard,
    random,
  ) {
    const shaderVaryings = `
      varying vec2 vuv;   // varying texture coordinates
      varying vec3 vpl;   // varying vertex position to local object center 
      varying vec3 vpc;   // varying vertex position to camera
      varying vec3 vpw;   // varying vertex position to world
      varying vec3 vpz;   // varying object center to world
      varying vec3 vnl;   // varying normals to local object center
      varying vec3 vnc;   // varying normals to camera
      varying vec3 vnw;   // varying normals to world
      varying vec4 vps;   // varying position screen
    `;

    const shaderVaryingsSet = `
      vuv = uv;
      vpl = position;
      // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
      // vpw = (modelMatrix * vec4(position, 1.0)).xyz;
      vpz = (modelMatrix * vec4(vec3(0.0), 1.0)).xyz;
      // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);
      // vnl = normal;
      // vnc = normalize(normalMatrix * normal);
      // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    `;

    function initUniforms(shader) {
      shader.uniforms.time = { value: 0 };
      shader.uniforms.tint = { value: tint };
      shader.uniforms.dmin = { value: dmin };
      shader.uniforms.dmax = { value: dmax };
      shader.uniforms.mouse = { value: [0.5, 0.5] };
      shader.uniforms.camPos = { value: [0.0, 0.0, 0.0] };
      shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
      shader.uniforms.random = { value: random };
      shader.uniforms.billboard = { value: billboard === "true" ? true : false };
    }

    const shaderUniforms = `
      uniform float time, tint, dmin, dmax;
      uniform vec2 mouse;
      uniform vec3 ucolor, camPos;
      uniform sampler2D random;
      uniform bool billboard;
    `;

    let shaderFunctions = `
  
      #define ONE_PI 3.14159265359             
      #define TWO_PI 6.28318530718
  
      #define msin(x) sin(mod(x,TWO_PI))
      #define mcos(x) cos(mod(x,TWO_PI))
  
      #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

      mat4 rotation3d(vec3 axis, float angle) {
        axis = normalize(axis);
        float s = sin(angle);
        float c = cos(angle);
        float oc = 1.0 - c;
        return mat4(
          oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
          oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
          oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
          0.0,                                0.0,                                0.0,                                1.0
        ); }
  
    `;

    mat.onBeforeCompile = shader => {
      // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

      const basicVertex = shader.vertexShader.split("void main() {");
      const basicVertexDef = basicVertex[0];
      const basicVertexMain = basicVertex[1].split("}")[0];

      const basicFragment = shader.fragmentShader.split("void main() {");
      const basicFragmentDef = basicFragment[0];
      const basicFragmentMain = basicFragment[1].split("}")[0];

      shader.vertexShader = `
  
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        ${basicVertexDef}
  
        void main() {
  
          ${shaderVaryingsSet}
          ${basicVertexMain}

          if (billboard == true) {
          
            mat4  MatrixLocalToWorld = modelMatrix;

            mat3  MatrixWorldToLocal = mat3(transpose(inverse(modelMatrix)));
    
            vec3  LocalPosition = position;
    
            vec3  WorldCameraPosition = camPos;
    
            vec3  WorldObjectCenter = ( MatrixLocalToWorld * vec4(0.0,0.0,0.0,1.0) ).xyz;
    
            vec3  WorldObjectToCamera = ( WorldCameraPosition - WorldObjectCenter );
    
            vec3  WorldObjectToCameraDirection = normalize( WorldObjectToCamera );
    
                  // --- rotation X
    
            // vec3  LocalDirectionLeft = vec3(-1.0, 0.0, 0.0);
    
            // vec3  WorldDirectionLeft = LocalDirectionLeft * MatrixWorldToLocal ;
    
            // float RotationAngleLeft = WorldObjectToCameraDirection.y 
            // * ONE_PI*0.5 - 0.25*WorldObjectToCameraDirection.y;
    
            // LocalPosition = (vec4(LocalPosition,1.0) 
            // * rotation3d( WorldDirectionLeft, RotationAngleLeft ) ).xyz;
    
                  // --- rotation Y
    
            vec3  LocalDirectionUp = vec3(0.0,1.0,0.0);
    
            vec3  WorldDirectionUp = LocalDirectionUp * MatrixWorldToLocal;
    
            float RotationAngleUp = - atan(WorldObjectToCamera.z, WorldObjectToCamera.x)
            + 0.5*ONE_PI;
    
            LocalPosition = (vec4(LocalPosition,1.0) 
            * rotation3d( WorldDirectionUp, RotationAngleUp ) ).xyz;
    
                  // --- output

            // LocalPosition.y += 0.05*sin(time);
    
            gl_Position = projectionMatrix * viewMatrix * modelMatrix 
            * vec4(LocalPosition, 1.0);

          }
  
        }
  
      `;

      shader.fragmentShader = `
  
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        // $ {basicFragmentDef}

        uniform vec3 diffuse;
        uniform float opacity;
        #ifndef FLAT_SHADED
          varying vec3 vNormal;
        #endif
        #include <common>
        #include <dithering_pars_fragment>
        #include <color_pars_fragment>
        #include <uv_pars_fragment>
        #include <uv2_pars_fragment>
        #include <map_pars_fragment>
        #include <alphamap_pars_fragment>
        #include <alphatest_pars_fragment>
        #include <aomap_pars_fragment>
        #include <lightmap_pars_fragment>
        #include <envmap_common_pars_fragment>
        #include <envmap_pars_fragment>
        #include <cube_uv_reflection_fragment>
        #include <fog_pars_fragment>
        #include <specularmap_pars_fragment>
        #include <logdepthbuf_pars_fragment>
        #include <clipping_planes_pars_fragment>
  
        void main() {
  
          // $ {basicFragmentMain}

          #include <clipping_planes_fragment>
          vec4 diffuseColor = vec4( diffuse, opacity );

          // ---------------------------------------------------------------------------
          
          float dist = length(vpz-camPos);
                dist = smoothstep(dmin, dmax, dist);
                // dist = 0.0;

          float amp = 0.05;
          float shift = (texture2D(random, vec2(time*0.01, vuv.y/5.0)).r-0.5) * smoothstep(0.8,1.0,sin(time*2.7)*cos(time*3.4));
          vec4  tex = texture2D(map, vuv + amp*vec2(shift,0.0));

          vec3  col = tex.rgb;
          float avg = dot(col, vec3(0.333));
                col = mix(col, ucolor * avg, tint);

          float rand = texture2D(random, vec2(0.0, vuv.y/5.0+avg*0.01)).r;

          float alpha = tex.a;
                alpha *= sin((time*10.0+(rand+0.5)*10.0))*0.5+0.5;

                // alpha -= 2.0*abs(vuv.x-0.5);

          // float rad = dot(vuv-0.5,vuv-0.5);

          float horgradient = smoothstep(0.5, 0.0, abs(vuv.x-0.5)) * (sin(time*3.0)*0.25+1.0);

                alpha += (1.0-dist) * horgradient * tex.a;

                // alpha += smoothstep(0.25, 0.0, rad) * (sin(time*3.0)*0.5+0.5);

                alpha = min(alpha, 1.0) - dist;
                alpha *= 0.85 + 0.15 * sin(time*4.0);

          vec3  outgoingLight = col; 
                diffuseColor.a = alpha;

          // ---------------------------------------------------------------------------

          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <specularmap_fragment>
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          #ifdef USE_LIGHTMAP
            vec4 lightMapTexel = texture2D( lightMap, vUv2 );
            reflectedLight.indirectDiffuse += lightMapTexel.rgb * lightMapIntensity * RECIPROCAL_PI;
          #else
            reflectedLight.indirectDiffuse += vec3( 1.0 );
          #endif
          #include <aomap_fragment>

          reflectedLight.indirectDiffuse *= diffuseColor.rgb;

          #include <envmap_fragment>
          #include <output_fragment>

          // #include <tonemapping_fragment>
          // #include <encodings_fragment>

          // #include <fog_fragment>
          // #include <premultiplied_alpha_fragment>
          // #include <dithering_fragment>        
  
        }
  
      `;

      initUniforms(shader);

      mat.userData.shader = shader;
    };

    return mat;
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let o = 0; o < this.objects?.length; o++) {
      for (let i = 0; i < this.unpatched[o]?.length; i++) {
        for (let c of this.unpatched[o][i].children) {
          if (c?.material?.map && this.params[o]) {
            if (this.params[o].billboard === "true") {
              const quat = new THREE.Quaternion();
              c.getWorldQuaternion(quat);
              c.setRotationFromQuaternion(quat.clone().invert());
              c.updateMatrix();
            }

            this.patch(
              c.material,
              this.params[o].color,
              this.params[o].tint,
              this.params[o].dmin,
              this.params[o].dmax,
              this.params[o].billboard,
              this.random,
            ),
              (c.material.blending = THREE.AdditiveBlending);
            c.material.transparent = true;

            this.unpatched[o].splice(i, 1);
            this.patched[o].push(c);
          }
        }
      }

      for (let i = 0; i < this.patched[o]?.length; i++) {
        const target = this.patched[o][i];
        if (!target.material?.userData?.shader?.uniforms) {
          return;
        }

        target.material.userData.shader.uniforms.time.value = performance.now() / 1000;
        // target.material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];

        const camPos = APP.scene.systems["fx-system"].getCamPos();
        target.material.userData.shader.uniforms.camPos.value = [camPos.x, camPos.y, camPos.z];
      }
    }
  },
});
