const metrikaIDs = [
  // hubs
  91375135,
];

export const METRICA_GOALS = {
  ENTER_GUEST: "enter-guest",
  ENTER_DREAM: "enter-dream",
  DOWNLOAD: "download-video",
};

function initMetrika() {
  (function (m, e, t, r, i, k, a) {
    // @ts-ignore
    m[i] =
      // @ts-ignore
      m[i] ||
      function () {
        // @ts-ignore
        (m[i].a = m[i].a || []).push(arguments);
      };
    // @ts-ignore
    m[i].l = 1 * new Date();
    for (let j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === r) {
        return;
      }
    }
    // @ts-ignore
    (k = e.createElement(t)),
      // @ts-ignore
      (a = e.getElementsByTagName(t)[0]),
      // @ts-ignore
      (k.async = 1),
      // @ts-ignore
      (k.src = r),
      // @ts-ignore
      a.parentNode.insertBefore(k, a);
  })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", `ym`);

  metrikaIDs.forEach(id => {
    // @ts-ignore
    global[`ym`](id, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  });
}

initMetrika();

const metrikaReachGoalBase = (goal: string, id: number) => {
  // @ts-expect-error
  const ym = global[`ym`];

  if (ym) {
    ym(id, "reachGoal", goal);
  }
};

export const metrikaReachGoal = (goal: string) => {
  metrikaIDs.forEach(id => {
    metrikaReachGoalBase(goal, id);
  });
  //console.log(goal);
};
