const componentName = "spoke-helper-raygun"; // имя создаваемого компонента

const activationName = "applyHelperRaygun"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
};

export function spokeHelperRaygun(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  multiple: false,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    for (let i = 0; i < this.objects?.length; i++) {
      this.objects[i].parent.el.removeObject3D("object3d");
    }

    if (this.objects?.length > 0) {
      APP.scene.systems["fx-system"].checkrays = true;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    if (this.objects?.length > 0) {
      const cursor = APP.scene.systems["fx-system"].cursor;
      const intersection = APP.scene.systems["fx-system"].intersects;
      const click = APP.scene.systems["fx-system"].click;

      if (click && intersection.length > 0) {
        console.log(
          `****************************\nx:${cursor.x.toFixed(3)} y:${cursor.y.toFixed(3)}\nRaycasted meshes: `,
        );
        for (let i = 0; i < intersection.length; i++) {
          if (intersection[i]?.object?.isMesh) {
            console.log("#" + i + ": " + intersection[i]?.object?.name + "; ", intersection[i]?.object);
          }
        }
      }
    }
  },
});
