const componentName = "spoke-mesh-rotate"; // имя создаваемого компонента

const activationName = "applyMeshRotate"; // атрибут объекта в EventID, по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  rotx: 0.0,
  roty: 0.0,
  rotz: 0.0,
};

export function spokeMeshRotate(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.meshes = tHelper.getMeshesWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.meshes);

    // for (let i = 0; i < this.meshes?.length; i++) {
    // APP.scene.systems["fx-system"].mobileFixMaterial(this.meshes[i].material);
    // patchBasic(
    //   this.meshes[i].material,
    //   this.params[i].rotx,
    //   this.params[i].roty,
    //   this.params[i].rotz,
    //   );
    // }

    function patchBasic(mat, rotx = defaultParams.rotx, roty = defaultParams.roty, rotz = defaultParams.rotz) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying vertex position to local object center 
        varying vec3 vpc;   // varying vertex position to camera
        varying vec3 vpw;   // varying vertex position to world
        varying vec3 vpz;   // varying object center to world
        varying vec3 vnl;   // varying normals to local object center
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        // vuv = uv;
        // vpl = position;
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;
        // vpz = (modelMatrix * vec4(vec3(0.0), 1.0)).xyz;
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);
        // vnl = normal;
        // vnc = normalize(normalMatrix * normal);
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.rotx = { value: rotx };
        shader.uniforms.roty = { value: roty };
        shader.uniforms.rotz = { value: rotz };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
      }

      const shaderUniforms = `
        uniform float time;
        uniform float rotx, roty, rotz;
        uniform vec2 mouse;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
    
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            // vec3  pos = position;

                  // pos.yz *= rot(time*rotx);
                  // pos.xz *= rot(time*roty);
                  // pos.xy *= rot(time*rotz);

            // gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(pos, 1.0);

    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
    
            ${basicFragmentMain}     
    
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    // for (let i = 0; i < this.meshes?.length; i++) {
    //   // this.meshes[i].parent.rotation.x += (0.01 * this.params[i].rotx) || defaultParams.rotx;
    //   // this.meshes[i].parent.rotation.y += (0.01 * this.params[i].roty) || defaultParams.roty;
    //   // this.meshes[i].parent.rotation.z += (0.01 * this.params[i].rotz) || defaultParams.rotz;
    //   this.meshes[i].parent.rotation.y += 0.01;
    //   this.meshes[i].parent.updateMatrix();

    //   if (!this.meshes[i]?.material?.userData?.shader?.uniforms) {
    //     return;
    //   }
    //   this.meshes[i].material.userData.shader.uniforms.time.value = performance.now() / 1000;
    //   this.meshes[i].material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];
    // }

    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      target.rotation.x += 0.01 * this.params[i].rotx || defaultParams.rotx;
      target.rotation.y += 0.01 * this.params[i].roty || defaultParams.roty;
      target.rotation.z += 0.01 * this.params[i].rotz || defaultParams.rotz;

      // target.rotation.x += 0.05;
      // target.rotation.y += 0.1;

      target.updateMatrix();
    }
  },
});
