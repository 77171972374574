import React, { useCallback } from "react";
import { MainAuthorizationType } from "../../interfaces";
import { TipsAuthorizationStyles } from "../styles/styles";
import { METRICA_GOALS, metrikaReachGoal } from "../../../../utils/metrika";

export const TipsAuthorization: React.FC<MainAuthorizationType> = props => {
  const onClick = useCallback(() => {
    metrikaReachGoal(METRICA_GOALS.ENTER_GUEST);
    props.onFinish();
  }, []);
  return (
    <TipsAuthorizationStyles>
      Видео не найдено. Проверьте ваши данные или <a onClick={onClick}>войдите как гость</a>
    </TipsAuthorizationStyles>
  );
};
