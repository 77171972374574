import { randomTexture } from "./add-randomTexture-hubs.js";

const componentName = "spoke-surface-hologram"; // имя создаваемого компонента

const activationName = "applySurfaceHologram"; // атрибут объекта в EventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  alpha: true,
};

export function spokeSurfaceHologram(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.meshes = tHelper.getMeshesWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.meshes);

    const random = randomTexture.clone();
    random.wrapS = THREE.RepeatWrapping;
    random.wrapT = THREE.RepeatWrapping;
    random.minFilter = THREE.NearestFilter;
    random.magFilter = THREE.NearestFilter;

    for (let i = 0; i < this.meshes?.length; i++) {
      APP.scene.systems["fx-system"].mobileFixMaterial(this.meshes[i].material);
      if (this.params[i].alpha == "true") {
        this.meshes[i].material = new THREE.ShaderMaterial();
        this.meshes[i].material.alphaTest = 0.6;
        this.meshes[i].material.depthWrite = false;
        this.meshes[i].material.depthTest = true;
        this.meshes[i].material.transparent = true;
        this.meshes[i].material.blending = THREE.AdditiveBlending;
      }
      patchBasic(this.meshes[i].material, this.params[i].color, this.params[i].alpha);
    }

    function patchBasic(mat, ucolor = defaultParams.color, alpha = defaultParams.alpha) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying vertex position to local object center 
        varying vec3 vpc;   // varying vertex position to camera
        varying vec3 vpw;   // varying vertex position to world
        varying vec3 vpz;   // varying object center to world
        varying vec3 vnl;   // varying normals to local object center
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;
        // vpl = position;
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
        vpw = (modelMatrix * vec4(position, 1.0)).xyz;
        // vpz = (modelMatrix * vec4(vec3(0.0), 1.0)).xyz;
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);
        // vnl = normal;
        // vnc = normalize(normalMatrix * normal);
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.random = { value: random };
      }

      const shaderUniforms = `
        uniform float time;
        uniform vec2 mouse;
        uniform vec3 ucolor;
        uniform sampler2D random;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
    
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}
    
            // gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);

            float scale = 1.0;

            float wave = sin(time*3.0);
                  wave = smoothstep(0.8,0.9,wave);
            float amp = sin(time)*0.5+0.5;
                  amp *= 2.0;
            float shift = wave*amp*( sin(vpw.y*0.06*scale-time*6.0) + sin(vpw.y*20.0*scale+time*10.0) );

            shift *= sin(time*10.0);

            // shift = sin(vpw.y*TWO_PI*mouse.x*10.0 + time);
    
            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position + vec3(shift,0.0,0.0), 1.0);

    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
    
            ${basicFragmentMain}

            float scale = 100.0;

            vec2  uv = vuv;
    
            vec4  img = vec4(1.0);
        
            vec3  pos = vpw*scale;
                  pos.y = -pos.y;

            float height = pos.y*0.001*2.0;
            vec3  col = ucolor;
        
            float wave1 = sin(height*25.0-time*3.0);
                  wave1 += sin(height*10.0-time*5.0);
                  wave1 = smoothstep(0.0,1.0,wave1);
        
            float wave2 = sin(height*250.0+time*15.0);
                  wave2 += sin(height*350.0+time*8.7);
                  wave2 = wave2*0.5+0.5;
        
            float noise = texture2D(random, (vpw.xy - vpw.zx) + vec2(0.0,vpw.y-time*0.05) ).r;
        
            vec2  grid = fract(pos.xy*0.1*rot(0.3));
            float wave3 = smoothstep(0.5,1.0,sin(time+height*5.0));
            float len = 0.2*wave3;
            float lines = step(grid.x,len) +  step(grid.y,len);
        
                  img.rgb = mix(col, vec3(1.0), 0.15);
                  img.rgb += wave1*0.5;
                  img.rgb -= wave2*0.3;
        
                  img.rgb = mix(img.rgb,vec3(1.0),clamp(lines,0.0,1.0));
        
                  img.a = 1.0-wave2;
                  img.a -= wave1*noise;
                  img.a += lines*0.7;

            ${
              alpha == "true"
                ? ``
                : `
            vec3  src = gl_FragColor.rgb;
                  img.rgb = mix(src, img.rgb, img.a*0.5);
                  img.a = 1.0;
            `
            }
    
            gl_FragColor = img;	
          
    
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.meshes?.length; i++) {
      if (!this.meshes[i].uniforms) {
        this.meshes[i].uniforms =
          this.meshes[i].material?.userData?.shader?.uniforms ||
          this.meshes[i].children[0]?.material?.userData?.shader?.uniforms;
        return;
      }
      this.meshes[i].uniforms.time.value = performance.now() / 1000;
      // this.meshes[i].uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
