const componentName = "spoke-surface-glow"; // имя создаваемого компонента

const activationName = "applySurfaceGlow"; // атрибут объекта в EventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  freq: 0.0,
};

export function spokeSurfaceGlow(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.meshes = tHelper.getMeshesWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.meshes);

    for (let i = 0; i < this.meshes?.length; i++) {
      const mat = this.meshes[i].material.clone();
      APP.scene.systems["fx-system"].mobileFixMaterial(mat);
      this.meshes[i].material = mat;
      patchBasic(this.meshes[i].material, this.params[i].color, this.params[i].freq);
    }

    function patchBasic(mat, ucolor = defaultParams.color, freq = defaultParams.freq) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying vertex position to local object center 
        varying vec3 vpc;   // varying vertex position to camera
        varying vec3 vpw;   // varying vertex position to world
        varying vec3 vpz;   // varying object center to world
        varying vec3 vnl;   // varying normals to local object center
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        // vuv = uv;
        // vpl = position;
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;
        // vpz = (modelMatrix * vec4(vec3(0.0), 1.0)).xyz;
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);
        // vnl = normal;
        vnc = normalize(normalMatrix * normal);
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
      }

      const shaderUniforms = `
        uniform float time, freq;
        uniform vec2 mouse;
        uniform vec3 ucolor;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
    
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}
    
            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
    
            ${basicFragmentMain}
    
            vec3  col = gl_FragColor.rgb;

            float f = 1.0-vnc.z;
                  f *= cos(time*freq*TWO_PI)*0.5+0.5;

            vec3  glow = ucolor;
                  col = mix(col, glow, max(f, 0.1));

            gl_FragColor = vec4(col, 1.0);	
          
    
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.meshes?.length; i++) {
      if (!this.meshes[i].uniforms) {
        this.meshes[i].uniforms =
          this.meshes[i].material?.userData?.shader?.uniforms ||
          this.meshes[i].children[0]?.material?.userData?.shader?.uniforms;
        return;
      }
      this.meshes[i].uniforms.time.value = performance.now() / 1000;
      // this.meshes[i].uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
