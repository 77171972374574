import { COLLIDABLE_SYSTEM_NAME, CollidableSystem, CollisionCylinder, CollisionParam } from "../interfaces";

const system: Partial<CollidableSystem> = {
  collisionMap: {},
  collisionsParams: [],
  step: 1,

  getMapKey: function (position: THREE.Vector2) {
    return `${position.x}_${position.y}`;
  },
  getPositionMap: (() => {
    const position_out = new THREE.Vector2();
    return function (position: THREE.Vector2) {
      position_out.copy(position).divideScalar(this.step).round();
      return position_out;
    };
  })(),
  removeCollisionParam: function (collisionParam: CollisionParam) {
    collisionParam.keys.forEach((key: string) => {
      if (this.collisionMap[key]) {
        const index = this.collisionMap[key].indexOf(collisionParam);
        if (index !== -1) {
          this.collisionMap[key].splice(index, 1);
        }
      }
    });

    collisionParam.keys.splice(0);
  },
  updateCollisionCylinder: (() => {
    const box3 = new THREE.Box3();
    const size = new THREE.Vector3();
    const worldPosition = new THREE.Vector3();

    return function (collisionCylinder: CollisionCylinder, mesh: THREE.Mesh) {
      box3.setFromObject(mesh);
      box3.getSize(size);
      mesh.getWorldPosition(worldPosition);

      collisionCylinder.position.set(worldPosition.x, worldPosition.z);
      collisionCylinder.radius = Math.max(size.x, size.z) * 0.5;
      collisionCylinder.height = size.y;
      collisionCylinder.minY = box3.min.y;
      collisionCylinder.maxY = box3.max.y;
    };
  })(),
  updateCollisionMap: (() => {
    const box3 = new THREE.Box3();

    return function (collisionParam) {
      const getBound = () => {
        box3.setFromObject(collisionParam.mesh);
        box3.min.divideScalar(this.step).round();
        box3.max.divideScalar(this.step).round();
      };
      const addCollision = () => {
        for (let x = box3.min.x; x <= box3.max.x; x++) {
          for (let y = box3.min.z; y <= box3.max.z; y++) {
            const key = this.getMapKey({ x, y });

            if (!this.collisionMap[key]) {
              this.collisionMap[key] = [];
            }

            if (!this.collisionMap[key].includes(collisionParam)) {
              this.collisionMap[key].push(collisionParam);
              collisionParam.keys.push(key);
            }
          }
        }
      };

      this.removeCollisionParam(collisionParam);
      getBound();
      addCollision();
    };
  })(),
  refreshMap: function () {
    this.collisionMap = {};
    this.collisionsParams.forEach((collisionParam: CollisionParam) => this.updateCollisionMap(collisionParam));
  },
  setStep: function (step: number) {
    this.step = step;
    this.refreshMap();
  },
  registerMesh: function (mesh) {
    if (!this.collisionsParams.some((collisionParam: CollisionParam) => collisionParam.mesh === mesh)) {
      const collisionCylinder = {
        position: new THREE.Vector2(),
        radius: 0,
        height: 0,
        minY: 0,
        maxY: 0,
      };
      this.updateCollisionCylinder(collisionCylinder, mesh);
      const collisionParam = {
        mesh,
        collisionCylinder,
        keys: [],
      };
      this.collisionsParams.push(collisionParam);
      this.updateCollisionMap(collisionParam);
    }
  },
  unregisterMesh: function (mesh) {
    const collisionParam = this.collisionsParams.find((collisionParam: CollisionParam) => collisionParam.mesh === mesh);
    if (collisionParam) {
      this.removeCollisionParam(collisionParam);
      this.collisionsParams.splice(this.collisionsParams.indexOf(collisionParam), 1);
    }
  },
  intersectionOfCylinders: (() => {
    const subVec = new THREE.Vector2();

    return function (cylinder1, cylinder2) {
      subVec.copy(cylinder1.position);
      subVec.sub(cylinder2.position);

      const isIntersectXZ = subVec.length() <= cylinder1.radius + cylinder2.radius;
      const isIntersectY = cylinder1.maxY > cylinder2.minY && cylinder1.minY < cylinder2.maxY;

      return isIntersectXZ && isIntersectY;
    };
  })(),
  intersect: function (intersectCylinder, callback) {
    const dividePosition = this.getPositionMap(intersectCylinder.position);
    const key = this.getMapKey(dividePosition);
    if (this.collisionMap[key]) {
      this.collisionMap[key].forEach((collisionParam: CollisionParam) => {
        if (this.intersectionOfCylinders(intersectCylinder, collisionParam.collisionCylinder)) {
          callback(collisionParam.mesh);
        }
      });
    }
  },
};

AFRAME.registerSystem(COLLIDABLE_SYSTEM_NAME, system);
