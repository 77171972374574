import { styled } from "styled-components";
import { minWidth } from "../theme/helpers";
import { css } from "styled-components";
import { landscapeMinHeight, landscapeMinHeight2, portraitMinWidth, portraitMinWidth2 } from "../theme";
import { CollectInfoStyled } from "./CollectInfo/styles";

export const CollectStyled = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 3px;

  /* > * {
    margin: 0 5px;
  } */

  ${minWidth.sm(css`
    gap: 5px;
  `)}

  user-select: none;
  & * {
    user-select: none;
  }

  @media ((orientation: landscape) and (min-height: ${landscapeMinHeight}px)) or ((orientation: portrait) and (min-width: ${portraitMinWidth}px)) {
    ${CollectInfoStyled} {
      width: 105px;
      height: 44px;
    }
  }

  @media ((orientation: landscape) and (min-height: ${landscapeMinHeight2}px)) or ((orientation: portrait) and (min-width: ${portraitMinWidth2}px)) {
    ${CollectInfoStyled} {
      width: 120px;
      height: 52px;
    }
  }
`;
