/**
 * Global APP.scene.systems['hubs-systems'].soundEffectsSystem
 */

/* global fetch THREE */
import { setMatrixWorld } from "../../utils/three-utils";
import { SourceType } from "../../components/audio-params";
import { getOverriddenPanningModelType } from "../../update-audio-settings";
import type { AScene } from "aframe";
import { Audio, Object3D, PositionalAudio } from "three";
import { soundsAndUrls } from "./interfaces";

/**
 * Экспортируем все из интерфейсов, так как сторонние компоненты
 * пытаются получить константы из самого компонента
 */
export * from "./interfaces";

// Safari doesn't support the promise form of decodeAudioData, so we polyfill it.
function decodeAudioData(audioContext: AudioContext, arrayBuffer: ArrayBuffer) {
  return new Promise((resolve, reject) => {
    audioContext.decodeAudioData(arrayBuffer, resolve, reject);
  });
}

type AudioOrPositionalAudio = Audio | PositionalAudio;

export class SoundEffectsSystem {
  scene: AScene;
  sounds: Map<number, AudioBuffer>;
  audioContext: AudioContext;
  pendingAudioSourceNodes: AudioBufferSourceNode[];
  pendingPositionalAudios: Array<AudioOrPositionalAudio>;
  positionalAudiosStationary: Array<AudioOrPositionalAudio>;
  positionalAudiosFollowingObject3Ds: Array<{ positionalAudio: AudioOrPositionalAudio; object3D: Object3D }>;
  isDisabled: boolean;

  constructor(scene: AScene) {
    this.pendingAudioSourceNodes = [];
    this.pendingPositionalAudios = [];
    this.positionalAudiosStationary = [];
    this.positionalAudiosFollowingObject3Ds = [];

    this.audioContext = THREE.AudioContext.getContext();
    this.scene = scene;

    const loading = new Map();
    const load = (url: string) => {
      let audioBufferPromise = loading.get(url);
      if (!audioBufferPromise) {
        audioBufferPromise = fetch(url)
          .then(r => r.arrayBuffer())
          .then(arrayBuffer => decodeAudioData(this.audioContext, arrayBuffer));
        loading.set(url, audioBufferPromise);
      }
      return audioBufferPromise;
    };
    this.sounds = new Map();
    soundsAndUrls.map(([sound, url]) => {
      load(url).then((audioBuffer: AudioBuffer) => {
        this.sounds.set(sound, audioBuffer);
      });
    });

    this.isDisabled = window.APP.store.state.preferences.disableSoundEffects;
    // @ts-expect-error
    window.APP.store.addEventListener("statechanged", () => {
      const shouldBeDisabled = window.APP.store.state.preferences.disableSoundEffects;
      if (shouldBeDisabled && !this.isDisabled) {
        this.stopAllPositionalAudios();
        // TODO: Technically we should stop any other sounds that have been started,
        // but we do not hold references to these and they're short-lived so I didn't bother.
      }
      this.isDisabled = shouldBeDisabled;
    });
  }

  enqueueSound(sound: number, loop: boolean) {
    if (this.isDisabled) return null;
    const audioBuffer = this.sounds.get(sound);
    if (!audioBuffer) return null;
    // The nodes are very inexpensive to create, according to
    // https://developer.mozilla.org/en-US/docs/Web/API/AudioBufferSourceNode
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    this.scene.systems["hubs-systems"].audioSystem.addAudio({ sourceType: SourceType.SFX, node: source });
    source.loop = loop;
    this.pendingAudioSourceNodes.push(source);
    return source;
  }

  enqueuePositionalSound(sound: number, loop: boolean) {
    if (this.isDisabled) return null;
    const audioBuffer = this.sounds.get(sound);
    if (!audioBuffer) return null;

    const disablePositionalAudio = window.APP.store.state.preferences.disableLeftRightPanning;
    const positionalAudio = disablePositionalAudio
      ? new THREE.Audio(this.scene.audioListener)
      : new THREE.PositionalAudio(this.scene.audioListener);
    positionalAudio.setBuffer(audioBuffer);
    positionalAudio.loop = loop;
    if (!disablePositionalAudio) {
      const overriddenPanningModelType = getOverriddenPanningModelType();
      if (overriddenPanningModelType !== null) {
        // @ts-expect-error
        positionalAudio.panner.panningModel = overriddenPanningModelType;
      }
    }
    this.pendingPositionalAudios.push(positionalAudio);
    this.scene.systems["hubs-systems"].audioSystem.addAudio({
      sourceType: SourceType.SFX,
      node: positionalAudio
    });
    return positionalAudio;
  }

  playPositionalSoundAt(sound: number, position: PositionalAudio["position"], loop: boolean) {
    const positionalAudio = this.enqueuePositionalSound(sound, loop);
    if (!positionalAudio) return null;
    positionalAudio.position.copy(position);
    positionalAudio.matrixWorldNeedsUpdate = true;
    this.positionalAudiosStationary.push(positionalAudio);
  }

  playPositionalSoundFollowing(sound: number, object3D: Object3D, loop: boolean) {
    const positionalAudio = this.enqueuePositionalSound(sound, loop);
    if (!positionalAudio) return null;
    this.positionalAudiosFollowingObject3Ds.push({ positionalAudio, object3D });
    return positionalAudio;
  }

  playSoundOneShot(sound: number) {
    return this.enqueueSound(sound, false);
  }

  playSoundLooped(sound: number) {
    return this.enqueueSound(sound, true);
  }

  playSoundLoopedWithGain(sound: number) {
    if (this.isDisabled) return null;
    const audioBuffer = this.sounds.get(sound);
    if (!audioBuffer) return null;

    const source = this.audioContext.createBufferSource();
    const gain = this.audioContext.createGain();
    source.buffer = audioBuffer;
    source.connect(gain);
    this.scene.systems["hubs-systems"].audioSystem.addAudio({ sourceType: SourceType.SFX, node: gain });
    source.loop = true;
    this.pendingAudioSourceNodes.push(source);
    return { gain, source };
  }

  stopSoundNode(node: AudioBufferSourceNode) {
    const index = this.pendingAudioSourceNodes.indexOf(node);
    if (index !== -1) {
      this.pendingAudioSourceNodes.splice(index, 1);
    } else {
      node.stop();
      this.scene.systems["hubs-systems"].audioSystem.removeAudio({ node });
    }
  }

  stopPositionalAudio(inPositionalAudio: AudioOrPositionalAudio) {
    const pendingIndex = this.pendingPositionalAudios.indexOf(inPositionalAudio);
    if (pendingIndex !== -1) {
      this.pendingPositionalAudios.splice(pendingIndex, 1);
    } else {
      if (inPositionalAudio.isPlaying) {
        inPositionalAudio.stop();
      }
      if (inPositionalAudio.parent) {
        inPositionalAudio.removeFromParent();
      }
    }
    this.positionalAudiosStationary = this.positionalAudiosStationary.filter(
      positionalAudio => positionalAudio !== inPositionalAudio
    );
    this.positionalAudiosFollowingObject3Ds = this.positionalAudiosFollowingObject3Ds.filter(
      ({ positionalAudio }) => positionalAudio !== inPositionalAudio
    );
    this.scene.systems["hubs-systems"].audioSystem.removeAudio({ node: inPositionalAudio });
  }

  stopAllPositionalAudios() {
    for (let i = this.positionalAudiosStationary.length - 1; i >= 0; i--) {
      const positionalAudio = this.positionalAudiosStationary[i];
      this.stopPositionalAudio(positionalAudio);
    }

    for (let i = this.positionalAudiosFollowingObject3Ds.length - 1; i >= 0; i--) {
      const positionalAudioAndObject3D = this.positionalAudiosFollowingObject3Ds[i];
      const positionalAudio = positionalAudioAndObject3D.positionalAudio;
      this.stopPositionalAudio(positionalAudio);
    }
  }

  tick() {
    if (this.isDisabled) {
      return;
    }

    for (let i = 0; i < this.pendingAudioSourceNodes.length; i++) {
      this.pendingAudioSourceNodes[i].start();
    }
    this.pendingAudioSourceNodes.length = 0;

    for (let i = 0; i < this.pendingPositionalAudios.length; i++) {
      const pendingPositionalAudio = this.pendingPositionalAudios[i];
      this.scene.object3D.add(pendingPositionalAudio);
      pendingPositionalAudio.play();
    }
    this.pendingPositionalAudios.length = 0;

    for (let i = this.positionalAudiosStationary.length - 1; i >= 0; i--) {
      const positionalAudio = this.positionalAudiosStationary[i];
      if (!positionalAudio.isPlaying) {
        this.stopPositionalAudio(positionalAudio);
      }
    }

    for (let i = this.positionalAudiosFollowingObject3Ds.length - 1; i >= 0; i--) {
      const positionalAudioAndObject3D = this.positionalAudiosFollowingObject3Ds[i];
      const positionalAudio = positionalAudioAndObject3D.positionalAudio;
      const object3D = positionalAudioAndObject3D.object3D;
      if (!positionalAudio.isPlaying || !object3D.parent) {
        this.stopPositionalAudio(positionalAudio);
      } else {
        object3D.updateMatrices();
        setMatrixWorld(positionalAudio, object3D.matrixWorld);
      }
    }
  }
}
