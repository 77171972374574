// import { Reflector } from "./add-reflector-fast.js"
import { Reflector } from "./add-reflector-lite.js";
// import { Reflector } from "./add-reflector-upd.js"
// import { Reflector } from "./add-reflector-src.js"

AFRAME.registerComponent("dsc-reflector", {
  multiple: true,

  schema: {
    width: { type: "number", default: 3 },
    height: { type: "number", default: 3 },
    depth: { type: "number", default: 3 },
    color: { type: "color", default: "#AAA" },
    blurSize: { type: "number", default: 1 },
    blurAmount: { type: "number", default: 1 },
    alpha: { type: "number", default: 1 },
    textureWidth: { type: "number", default: 512 },
    textureHeight: { type: "number", default: 512 },
    blurWidth: { type: "number", default: 512 },
    blurHeight: { type: "number", default: 512 },
    additive: { type: "boolean", default: true },
  },

  init: function () {
    const rect = new THREE.CircleGeometry(this.data.width, 16);

    const reflectorParams = {
      textureWidth: this.data.textureWidth,
      textureHeight: this.data.textureHeight,
      blurWidth: this.data.blurWidth,
      blurHeight: this.data.blurHeight,
      blurSize: this.data.blurSize,
      blurAmount: this.data.blurAmount,
      alpha: this.data.alpha,
    };

    const reflector = new Reflector(rect, this.el.sceneEl.renderer, reflectorParams);
    if (this.data.additive) {
      reflector.material.transparent = true;
      reflector.material.blending = THREE.AdditiveBlending;
    }
    // reflector.position.set(0, 0.2, 0);
    // reflector.rotation.x = -Math.PI / 2;
    reflector.name = "reflector";
    reflector.renderOrder = -1000;
    reflector.updateMatrix();
    // window.reflector = reflector;

    this.el.setObject3D("mesh", reflector);

    this.mesh = reflector;
  },

  tick: function () {
    // this.mesh.update();
  },
});
