const componentName = "spoke-helper-alpha"; // имя создаваемого компонента

const activationName = "applyHelperAlpha"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  name: "untitled",
};

export function spokeHelperAlpha(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  multiple: false,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    this.alphamat = [];

    for (let i = 0; i < this.objects?.length; i++) {
      const spokeName = this.params[i].name || defaultParams.name;
      const obj = document.querySelector(`#environment-el .${spokeName}`)?.object3D;
      if (obj) {
        obj.traverse(o => {
          if (o.material?.transparent) {
            o.material.alphaTest = 0.6;
            o.material.depthWrite = false;
            o.material.depthTest = true;
            // o.material.transparent = false;
            o.material.needsUpdate = true;
            // console.log('ALPHAMAT', o.material);
          }
        });
      }
      // console.log('ALPHAMAT', this.alphamat[i]);
      this.objects[i].parent.el.removeObject3D("object3d");
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {},
});
