import { styled, css } from "styled-components";
import { ToolbarButtonStyled } from "../../../react-components/input/ToolbarButton/styles";
import { CollectStyled } from "../../Collect/styles";
import { TechnicalSupportBigMenuButtonStyled } from "../../TechnicalSupport/styles";

export type UiRootCustomStyledProps = {
  hideToolbars?: boolean;
  hideCollects?: boolean;
};

export const UiRootCustomStyled = styled.div<UiRootCustomStyledProps>`
  ${({ hideToolbars }) => {
    if (hideToolbars) {
      return css`
        ${ToolbarButtonStyled} {
          display: none;
        }
        ${TechnicalSupportBigMenuButtonStyled} {
          display: none;
        }
      `;
    }
  }}
  ${({ hideCollects }) => {
    if (hideCollects) {
      return css`
        ${CollectStyled} {
          display: none;
        }
      `;
    }
  }}
`;
