import Raven from "raven-js";
import configs from "./utils/configs";
import "./custom/utils/metrika";

export default function registerTelemetry() {
  const sentryDsn = configs.SENTRY_DSN;

  if (sentryDsn) {
    // console.log("Tracking: Sentry DSN: " + sentryDsn);
    Raven.config(sentryDsn).install();
  }
}
