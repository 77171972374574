export class TargetHelper {
  constructor() {
    this.scene = document.getElementById("environment-scene")?.object3D;
  }

  /*
  getObjectIdFromSpokeName(spokeName) {
    const obj = document.querySelector(`#environment-el .${spokeName}`)?.object3D;
    return obj?.children[2]?.userData?.objectId;
  }
  
  getEventIdFromSpokeName(spokeName) {
    const obj = document.querySelector(`#environment-el .${spokeName}`)?.object3D;
    return obj?.children[2]?.userData?.eventId;
  }
  
  getMeshesFromSpokeName(spokeName) {
    const obj = document.querySelector(`#environment-el .${spokeName}`).object3D;
    const meshes = [];
    obj.traverse(o => {
      if (o.type === 'Mesh') {
        meshes.push(o);
      }
    })
    return meshes;
  }

  findTarget(object, type) {
    for (let child of object.children) {
      if (type === child.type) {
        return child;
      }
      let answer = findTarget(child, type);
      if (answer !== undefined) return answer;
    }
  }
  */

  getMeshesWithEventID(eventId) {
    const meshes = [];
    this.scene?.traverse(o => {
      if (o.userData?.eventId === eventId) {
        // console.log(`>>>> ${eventId} FOUND HERE`, o); // data-container (cube) is found
        o.material.visible = false; // making cube from transparent state to invisible
        if (o.parent?.children[0]?.children[0]) {
          o.parent.children[0].children[0].traverse(m => {
            // looking for a object's real mesh
            if (m.type === "Mesh") {
              m.userData.objectId = o.userData.objectId;
              meshes.push(m);
            }
          });
        }
      }
    });
    return meshes;
  }

  getObjectsWithEventID(eventId) {
    const objects = [];
    this.scene?.traverse(o => {
      if (o.userData?.eventId === eventId) {
        // console.log(`>>>> ${eventId} FOUND HERE`, o); // data-container (cube) is found
        o.material.visible = false; // making cube from transparent state to invisible
        o.parent.children[0].userData.objectId = o.userData.objectId;
        objects.push(o.parent.children[0]);
      }
    });
    return objects;
  }

  getObjectsWithName(spokeName) {
    const objects = [];
    this.scene.traverse(o => {
      if (o.name.includes(spokeName) && o.isGroup) {
        // console.log(`>>>> ${spokeName} OBJECT FOUND HERE`, o, o.children);
        objects.push(o);
      }
    });
    return objects;
  }

  getObjectsPositions(objects) {
    const positions = [];
    for (let obj of objects) {
      const pos = new THREE.Vector3();
      obj.getWorldPosition(pos);
      positions.push(pos);
    }
    return positions;
  }

  getParamsFromMeshes(meshes) {
    const meshParams = [];
    for (let mesh of meshes) {
      const params = {};
      let data = mesh.userData?.objectId;
      data = data.split(";");
      for (let chunk of data) {
        chunk = chunk.split(":");
        if (!chunk[1]) {
          break;
        }
        const key = chunk[0]?.trim();
        const val = chunk[1]?.trim();
        params[key] = val;
      }
      meshParams.push(params);
    }
    return meshParams;
  }

  getParamsFromSpokeName(spokeName) {
    const params = {};
    const obj = document.querySelector(`#environment-el .${spokeName}`)?.object3D;
    let data = obj?.children[2]?.userData?.eventId;
    data = data.split(";");
    for (let chunk of data) {
      chunk = chunk.split(":");
      if (!chunk[1]) {
        break;
      }
      const key = chunk[0]?.trim();
      const val = chunk[1]?.trim();
      params[key] = val;
    }
    return params;
  }

  meshesToMaterials(meshes) {
    const mats = [];
    for (let mesh of meshes) {
      mats.push(mesh.material);
    }
    return mats;
  }

  arrayLocalToWorld(array, matrix) {
    const world = [];
    for (let i = 0; i < array.length; i += 3) {
      let v = new THREE.Vector3(array[i], array[i + 1], array[i + 2]);
      v.applyMatrix4(matrix);
      world.push(v.x, v.y, v.z);
    }
    return world;
  }

  meshesToVertices(meshes, world = false) {
    const vpos = [];
    for (let i = 0; i < meshes.length; i++) {
      if (world) {
        meshes[i].parent.parent.updateWorldMatrix(false, true);
        vpos.push(arrayLocalToWorld(meshes[i].geometry.attributes.position.array, meshes[i].matrixWorld));
      } else {
        vpos.push(meshes[i].geometry.attributes.position.array);
      }
    }
    return vpos;
  }
}
