import { spokeAreaAurora } from "./spoke-area-aurora";
import { spokeAreaGlow } from "./spoke-area-glow";
import { spokeAreaParticles } from "./spoke-area-particles";
import { spokeForceCells } from "./spoke-force-cells";
import { spokeMeshFireflies } from "./spoke-mesh-fireflies";
import { spokeMeshFlows } from "./spoke-mesh-flows";
import { spokeObjPortal } from "./spoke-obj-portal";
import { spokeObjSpinner } from "./spoke-obj-spinner";
import { spokeObjTicker } from "./spoke-obj-ticker";
import { spokeSurfaceGlow } from "./spoke-surface-glow";
import { spokeSurfaceHologram } from "./spoke-surface-hologram";
import { spokeMediaHologram } from "./spoke-media-hologram";
import { TargetHelper } from "./add-targetHelper-hubs";
import { spokeObjWaver } from "./spoke-obj-waver";
import { spokeMeshRotate } from "./spoke-mesh-rotate";
import { spokeObjOrbit } from "./spoke-obj-orbit";
import { spokeHelperRaygun } from "./spoke-helper-raygun";
import { spokeHelperAlpha } from "./spoke-helper-alpha";
import { spokeObjSkybox } from "./spoke-obj-skybox";
import { spokeObjSphereCells } from "./spoke-obj-sphere-cells";

import "./dsc-reflector.js";
import { spokeObjMirror } from "./spoke-obj-mirror";

// APP.scene.systems["fx-system"].

// floor-1
// https://localhost:8080/hub.html?hub_id=KJoqaLA
// https://sberwonderverse.arvr.sberlabs.com/8M6jqaz/floor-1

// floor-2
// https://localhost:8080/hub.html?hub_id=oqEqZQU
// https://sberwonderverse.arvr.sberlabs.com/8Ztvm9f/floor-2

// floor-3
// https://localhost:8080/hub.html?hub_id=G8MfsRK
// https://sberwonderverse.arvr.sberlabs.com/Ejbe9kr/floor-3

// floor-1-test
// https://localhost:8080/hub.html?hub_id=8yGRtQZ

// floor-2-test
// https://localhost:8080/hub.html?hub_id=EpYVmUP

// floor-rnd
// https://localhost:8080/hub.html?hub_id=SKvAbFU
// https://sberwonderverse.arvr.sberlabs.com/SKvAbFU/

AFRAME.registerSystem("fx-system", {
  schema: {},

  init: function () {
    this.onSceneLoaded = () => {
      if (!this.loaded) {
        this.loaded = true;
        this.tHelper = new TargetHelper();
        this.avatar = document.querySelector("#avatar-rig")?.object3D;
      }
    };

    this.loaded = false;
    this.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded, { once: true });

    this.sceneEl = document.querySelector("a-scene");
    this.scene = this.sceneEl.object3D;
    this.camera = this.sceneEl.camera;
    this.cursor = { x: 0, y: 0 };

    // console.log("CAMERA", this.camera);

    this.allowRender = true;
    this.frameCount = 0;

    this.camPos = new THREE.Vector3(0, 0, 0);
    this.avaPos = new THREE.Vector3(0, 0, 0);

    this.renderer = this.sceneEl.renderer;

    // --------------------------------------------------------------------------

    this.raycaster = new THREE.Raycaster();
    this.intersects = [];
    this.checkrays = false;

    this.click = false;
    this.press = false;
    this.status = 0;

    // --------------------------------------------------------------------------

    window.addEventListener("pointerdown", () => {
      this.press = true;
    });

    window.addEventListener("pointerup", () => {
      this.press = false;
    });

    window.addEventListener("mousemove", event => {
      this.cursor.x = event.clientX / innerWidth;
      this.cursor.y = event.clientY / innerHeight;
    });

    // --------------------------------------------------------------------------

    const fxScene = document.createElement("a-entity");
    this.sceneEl.appendChild(fxScene);

    spokeAreaGlow(fxScene);
    spokeAreaAurora(fxScene);
    spokeMeshFireflies(fxScene);
    spokeMeshFlows(fxScene);
    spokeSurfaceGlow(fxScene);
    spokeSurfaceHologram(fxScene);
    spokeForceCells(fxScene);
    spokeObjPortal(fxScene);
    spokeObjSpinner(fxScene);
    spokeAreaParticles(fxScene);
    spokeObjTicker(fxScene);
    spokeMediaHologram(fxScene);
    spokeObjWaver(fxScene);
    spokeMeshRotate(fxScene);
    spokeObjOrbit(fxScene);
    spokeHelperRaygun(fxScene);
    spokeHelperAlpha(fxScene);
    spokeObjSkybox(fxScene);
    spokeObjSphereCells(fxScene);
    spokeObjMirror(fxScene);
  },

  getCamPos: function () {
    this.camera.getWorldPosition(this.camPos);
    return this.camPos;
  },

  getAvaPos: function () {
    this.avatar.getWorldPosition(this.avaPos);
    return this.avaPos;
  },

  mobileFixMaterial: function (mat) {
    mat._emissive = { value: null };
    mat._emissiveIntensity = { value: null };
    mat._emissiveMap = { value: null };
  },

  remove: function () {
    this.sceneEl.removeEventListener("environment-scene-loaded", this.onSceneLoaded);
  },

  changeBloom: function () {
    if (this.loaded) {
      const height = this.getAvaPos().y;
      this.sceneEl.systems["visual-effects-system"].bloomPass.strength = 0.5 - 0.05 * height;
      // this.renderer.toneMappingExposure = this.cursor.x;
      // this.scene.traverse(o => {
      //   if (o.material) o.material.needsUpdate = true;
      // });
    }
  },

  tick: function () {
    if (this.loaded) {
      this.frameCount++;
      if (this.press) this.status++;
      else this.status = 0;
      if (this.status === 1) this.click = true;
      else this.click = false;
    }
    if (this.camera && this.checkrays) {
      this.raycaster.setFromCamera({ x: this.cursor.x * 2 - 1, y: -(this.cursor.y * 2 - 1) }, this.camera);
      this.intersects.splice(0);
      this.raycaster.intersectObject(this.scene, true, this.intersects);
    }
  },
});
