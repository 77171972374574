import { styled } from "styled-components";

// .currCats
export const CollectInfoCounterFindedStyled = styled.span`
  font-weight: 600;
`;

// .counterCat
export const CollectInfoCounterWrapperStyled = styled.div`
  font-family: "SB Sans Interface", sans-serif;
  /* font-size: 20px; */
  font-size: 16px;
  color: #ffffff;
  line-height: 24.88px;
  /* padding-right: 10px; */
  letter-spacing: 1px;
`;

export const CollectInfoIconIconStyled = styled.img`
  /* height: 26px; */
  height: 70%;
`;

// .iconCat
export const CollectInfoIconWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* justify-content: space-evenly; */
  /* align-items: flex-end;
  flex-direction: column; */
`;

// .catsCollection
export const CollectInfoStyled = styled.div`
  flex: none;
  background: rgba(38, 62, 84, 0.8);
  border-radius: 32px;
  cursor: pointer;
  /* height: 64px;
  width: 112px; */
  width: 100px;
  height: 40px;
  padding: 0 10px;
  /* 
  @media screen and (orientation: landscape) and (max-height: 570px) {
    width: 120px;
    height: 52px;
  } */

  /* @media screen and (orientation: landscape) and (max-height: 570px) {
    width: 120px;
    height: 52px;
  } */
`;
