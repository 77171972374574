import { styled } from "styled-components";
import { ToolbarButtonStyled } from "../../input/ToolbarButton/styles";
import { ToolbarButtonIconContainerStyled } from "../../input/ToolbarButton/styles";

export const AudioPopoverButtonsContainer = styled.div`
  > ${ToolbarButtonStyled} {
    > ${ToolbarButtonIconContainerStyled} {
      img,
      svg {
        width: unset;
        height: unset;
      }
    }
  }
`;
