/** @jsx createElementEntity */
import { createElementEntity, createRef, renderAsEntity } from "../../utils/jsx-entity";
import buttonSrc from "../../assets/hud/button.9.png";
import buttonSrc30 from "../../assets/hud/btn_transparent_30.png";
import buttonSrc50 from "../../assets/hud/btn_transparent_50.png";
import buttonSrc80 from "../../assets/hud/btn_transparent_80.png";
import { textureLoader } from "../../utils/media-utils";
import { BUTTON_TYPES } from "../../systems/single-action-button-system";
import { Layers } from "../../components/layers";
// import { isHubsRoomUrl, isLocalHubsUrl } from "../../utils/media-url-utils";
import { getNextHub } from "../../utils/get-next-hub";
import { AElement, AElementECS } from "aframe";
import { Object3D, Texture, EventListener } from "three";
import configs from "../../utils/configs";
import { metrikaReachGoal } from "../utils/metrika";

// import { textureLoader } from "../../utils/media-utils";
// import { BUTTON_TYPES } from "../../systems/single-action-button-system";
// import { Layers } from "../../components/layers";
// import { changeHub } from "../../change-hub";
// // import { isHubsRoomUrl, isLocalHubsUrl } from "../../utils/media-url-utils";
// import { getNextHub } from "../../utils/get-next-hub";
// import configs from "../../utils/configs";

export const btnTextures = [
  textureLoader.load(buttonSrc),
  textureLoader.load(buttonSrc30),
  textureLoader.load(buttonSrc50),
  textureLoader.load(buttonSrc80),
];

type ButtonEntityProps = {
  text: string;
  size: [number, number];
  texture: Texture;
  type?: typeof BUTTON_TYPES.ACTION;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
  name?: string;
};

// eslint-disable-next-line react/prop-types
export function ButtonEntity({
  text = "test",
  size = [1, 1],
  texture = btnTextures[0],
  type = BUTTON_TYPES.ACTION,
  ...props
}: ButtonEntityProps) {
  const labelRef = createRef();
  return (
    <entity
      name={"Button"}
      slice9={{
        size,
        insets: [64, 66, 64, 66],
        // TODO Fix?
        // @ts-expect-error
        texture,
      }}
      cursorRaycastable
      remoteHoverTarget
      hoverButton={{ type }}
      textButton={{ labelRef }}
      singleActionButton
      layers={1 << Layers.CAMERA_LAYER_UI}
      {...props}
    >
      <entity
        ref={labelRef}
        layers={1 << Layers.CAMERA_LAYER_UI}
        text={{
          value: text,
          side: THREE.FrontSide,
          color: "#000000",
          textAlign: "center",
          anchorX: "center",
          anchorY: "middle",
          fontUrl: `${configs.BASE_ASSETS_PATH}assets/fonts/SB-Sans-Interface.ttf`,
        }}
        position={[0, 0, 0.01]}
        name={props.name ? `${props.name} Label` : "Button Label"}
        visible={text.length > 1}
      />
    </entity>
  );
}

function Button3DObj(
  text: string = "test",
  position: [number, number, number] = [0, 0, 0],
  rotation: [number, number, number] = [0, 0, 0],
  size: [number, number] = [1, 1],
  scale: [number, number, number] = [1, 1, 1],
  texture: Texture = btnTextures[0],
  onClick: EventListener<Event, "custom-click", Object3D<Event>>,
) {
  const eid = renderAsEntity(APP.world, ButtonEntity({ text, position, rotation, size, scale, texture }));
  const obj = APP.world.eid2obj.get(eid);

  if (!obj) {
    return;
  }

  // @ts-expect-error
  obj.prototype = Object.create(THREE.EventDispatcher.prototype);

  obj.addEventListener("custom-click", onClick);
  return obj;
}

/**
 * Ссылка для перехода в хаб
 */
export function createHubLinkButton(
  el: AElementECS,
  _componentName: string,
  componentData: {
    href: string;
  },
) {
  // eslint-disable-next-line prefer-const
  let position: [number, number, number] = [0, 0, 0];
  // eslint-disable-next-line prefer-const
  let scale: [number, number, number] = [0.5, 2, 0.2];
  // eslint-disable-next-line prefer-const
  let rotation: [number, number, number] = [0, 0, 0];

  // console.log("createHubLinkButton el", el, componentData);
  const href = /^https?:\/\//.test(componentData.href) ? componentData.href : `${location.origin}${componentData.href}`;

  const buttonObj = Button3DObj("", position, rotation, [2, 0.6], scale, btnTextures[3], async () => {
    if (APP.store.state.preferences.fastRoomSwitching) {
      const url = new URL(href);

      const params = new URLSearchParams(url.search);

      const hubName = params.get("event");

      if (hubName) {
        metrikaReachGoal("clicked-" + hubName);
        getNextHub(hubName)
          .then(hubId => {
            hubId && global?.changeHub({ id: hubId });
          })
          .catch(console.error);
      }
    } else {
      document.location = href;
    }
  });

  if (!buttonObj || !el.object3D) {
    return;
  }

  el.object3D.add(buttonObj);

  el.visible = true;
  el.object3D.visible = true;
}
