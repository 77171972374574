import { styled } from "styled-components";

export const ModalAuthorizationStyles = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 460px;
`;

export const MainAuthorizationStyles = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: #fff;

  @media screen and (min-width: 400px) {
    width: 398px;
  }

  @media screen and (max-height: 450px) and (orientation: landscape) {
    width: 720px;
  }
`;

export const HeaderMainAuthorizationStyles = styled.div`
  color: #000;
  font-family: var(--font-family-default);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: auto;
  margin-bottom: 8px;
`;

export const DescriptionMainAuthorizationStyles = styled.div`
  color: #818181;
  font-family: var(--font-family-default);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-right: auto;
`;
export const LabelTextMainAuthorizationStyles = styled.label`
  margin-top: 20px;
  margin-bottom: 4px;
  color: #eb8017;
  font-family: var(--font-family-default);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  @media screen and (max-width: 400px) and (orientation: portrait) {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 4px;
  }
  @media screen and (max-height: 400px) and (orientation: landscape) {
    margin: 0px;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 4px;
  }
`;

export const InputFieldBlockStyles = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-height: 450px) and (orientation: landscape) {
    flex-direction: row;
  }
`;

export const TextMainAuthorizationStyles = styled.input`
  font-size: 16px;
  color: black;
  width: 100%;
  padding: 5px;
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: #fafafa;

  color: #000;
  font-family: var(--font-family-default);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media screen and (max-width: 400px) and (orientation: portrait) {
    font-size: 13px;
  }
  @media screen and (max-height: 400px) and (orientation: landscape) {
    font-size: 13px;
  }
`;
export const ButtonMainAuthorizationStyles = styled.button`
  display: flex;
  height: 56px;
  padding: 16px 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #f78937;
  background: linear-gradient(180deg, #ffab42 0%, #f78937 100%);
  margin-top: 32px;

  color: #fff;
  font-family: var(--font-family-default);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;

  &:hover {
    color: white;
    background: linear-gradient(180deg, #ffb84e 0%, #fa9a43 100%);
  }

  &:active {
    background: linear-gradient(180deg, #ff951e 0%, #f66e14 100%);
  }

  &:disabled {
    color: gray;
    border: 1px solid #d9d9d9;
    background: linear-gradient(180deg, #f2f2f2 0%, #d9d9d9 100%);
  }
`;
export const TipsAuthorizationStyles = styled.div`
  margin: auto;
  background-color: #00000099;
  padding: 20px 10px;
  text-align: center;
  color: white;
  width: 80%;
  line-height: 1.5;
  margin-top: 40px;

  @media screen and (max-height: 450px) and (orientation: landscape) {
    position: absolute;
    width: 350px;
    margin: 5% 6% 30% 45%;
    border-radius: 8px;
    padding: 8px 4px;
  }

  @media screen and (max-height: 370px) and (orientation: landscape) {
    position: absolute;
    width: 350px;
    margin: 3% 0% 31% 46%;
    border-radius: 8px;
    padding: 8px 4px;
  }

  a {
    color: orange;
    cursor: pointer;
  }
`;
