import React, { useEffect } from "react";

import { updateGlobalSFXVolume } from "./helpers/updateGlobalSFXVolume";
import { SOUND_VDNH_BACKGROUND } from "../../../systems/sound-effects-system";

type VDNHBackgroundMusicProps = {
  entered: boolean;
};

export const VDNHBackgroundMusic: React.FC<VDNHBackgroundMusicProps> = ({ entered }) => {
  useEffect(() => {
    if (!entered) {
      return;
    }

    let audio: AudioBufferSourceNode | null = null;
    {
      updateGlobalSFXVolume(16);

      const audioBuffer: AudioBufferSourceNode =
        AFRAME.scenes[0].systems["hubs-systems"].soundEffectsSystem.playSoundLooped(SOUND_VDNH_BACKGROUND);

      audio = audioBuffer;
    }

    return () => {
      if (audio) {
        audio.disconnect();
      }
    };
  }, [entered]);

  return <></>;
};
