/* eslint-disable @calm/react-intl/missing-formatted-message */
import React, { useCallback, useEffect, useState } from "react";
import {
  ButtonMainAuthorizationStyles,
  DescriptionMainAuthorizationStyles,
  HeaderMainAuthorizationStyles,
  InputFieldBlockStyles,
  MainAuthorizationStyles,
  ModalAuthorizationStyles,
} from "./styles/styles";
import { TipsAuthorization } from "./component/tips";
import { AuthorizationState, MainAuthorizationType } from "../interfaces";
import { InputField } from "./component/inputField";
import { AElement } from "aframe";
import { getHashValue } from "../../../utils/url";
import { AutorizationData, MediaOptions, VideoAttribute } from "../data";
import { METRICA_GOALS, metrikaReachGoal } from "../../../utils/metrika";

function formUrl(id: string, pin: string) {
  return `${AutorizationData.prefixUrlS3}video_${id}_${pin}.mp4`;
}

function setAttribute(element: AElement, url: string) {
  element.setAttribute("media-loader", { ...VideoAttribute, mediaOptions: { ...MediaOptions, href: url }, src: url });
}

export const AuthorizationModal: React.FC<MainAuthorizationType> = props => {
  const [idValue, setIIdValue] = useState("");
  const [pinValue, setPinValue] = useState("");
  const [authorizationState, setAuthorizationState] = useState<AuthorizationState>("init");

  const callbackError = useCallback(() => {
    setAuthorizationState("failed");
  }, []);

  const onEnter = useCallback(() => {
    setAuthorizationState("check");
    const url = formUrl(idValue, pinValue);
    fetch(`${url}`)
      .then(e => {
        if (!e.ok) {
          callbackError();
          return;
        }
        metrikaReachGoal(METRICA_GOALS.ENTER_DREAM);

        const groupDream = document.querySelector(".GroupVideoDream");
        if (groupDream) {
          const video = groupDream.children[0] as AElement;
          if (video) {
            setAttribute(video, url);
          }
        }
        setAuthorizationState("success");
        props.onFinish();
      })
      .catch(() => {
        callbackError();
      });
  }, [props, idValue, pinValue, callbackError]);

  useEffect(() => {
    const id = getHashValue("dream_video_id");
    const pin = getHashValue("dream_video_pin");

    if (id && pin) {
      setIIdValue(id);
      setPinValue(pin);
    }
  }, []);

  const handleLoginChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIIdValue(event.target.value);
  }, []);
  const handlePinChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPinValue(event.target.value);
  }, []);

  return (
    <ModalAuthorizationStyles>
      <MainAuthorizationStyles>
        <HeaderMainAuthorizationStyles>Future Universe</HeaderMainAuthorizationStyles>
        <DescriptionMainAuthorizationStyles>
          Введите свои ID и PIN для входа в проект.
        </DescriptionMainAuthorizationStyles>
        <InputFieldBlockStyles>
          <InputField id={"login_input"} label={"ID"} text={idValue} onChange={handleLoginChange} />
          <InputField id={"pass_input"} label={"PIN"} text={pinValue} onChange={handlePinChange} />
        </InputFieldBlockStyles>
        <ButtonMainAuthorizationStyles disabled={authorizationState === "check"} onClick={onEnter}>
          Найти мечту
        </ButtonMainAuthorizationStyles>
      </MainAuthorizationStyles>
      {authorizationState === "failed" && <TipsAuthorization {...props} />}
    </ModalAuthorizationStyles>
  );
};
