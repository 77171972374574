import { Reflector } from "./add-reflector-lite.js";

const componentName = "spoke-obj-mirror"; // имя создаваемого компонента

const activationName = "applyObjMirror"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white", // main color
  blurSize: 1,
  blurAmount: 1,
  alpha: 1,
  textureWidth: 512,
  textureHeight: 512,
  blurWidth: 512,
  blurHeight: 512,
  additive: "true",
};

export function spokeObjMirror(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    for (let i = 0; i < this.objects?.length; i++) {
      const reflectorParams = {
        textureWidth: this.params[i].textureWidth || defaultParams.textureWidth,
        textureHeight: this.params[i].textureHeight || defaultParams.textureHeight,
        blurWidth: this.params[i].blurWidth || defaultParams.blurWidth,
        blurHeight: this.params[i].blurHeight || defaultParams.blurHeight,
        blurSize: this.params[i].blurSize ?? defaultParams.blurSize,
        blurAmount: this.params[i].blurAmount ?? defaultParams.blurAmount,
        alpha: this.params[i].alpha ?? defaultParams.alpha,
      };

      const reflector = new Reflector(new THREE.CircleGeometry(0.5, 16), this.el.sceneEl.renderer, reflectorParams);

      if (this.params[i].additive != "false") {
        reflector.material.transparent = true;
        reflector.material.blending = THREE.AdditiveBlending;
      }

      reflector.name = "reflector";
      reflector.renderOrder = -1000;
      reflector.updateMatrix();

      this.objects[i].parent.el.removeObject3D("object3d");
      this.objects[i].el.setObject3D("mesh", reflector);
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {},
});
