import { styled } from "styled-components";

export const TechnicalSupportBigMenuButtonStyled = styled.a`
  user-select: none;
  height: 64px;
  width: 181px;
  position: absolute;
  right: 20px;
  bottom: 20px;

  &:hover {
    opacity: 0.8;
  }

  span {
    position: absolute;
    color: #fff;
    top: 26px;
    left: 11px;
  }

  img {
    max-height: 100%;
    pointer-events: none;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;
