import { ASystem } from "aframe";

export const COLLIDABLE_SYSTEM_NAME = "collidable-system";

export type CollisionCylinder = {
  position: THREE.Vector2;
  radius: number;
  height: number;
  minY: number;
  maxY: number;
};
export type CollisionParam = {
  mesh: THREE.Mesh;
  collisionCylinder: CollisionCylinder;
  keys: string[];
};

type CollisionMap = Record<string, THREE.Mesh[]>;

export interface CollidableSystem extends ASystem {
  registerMesh: (mesh: THREE.Mesh) => void;
  unregisterMesh: (mesh: THREE.Mesh) => void;
  setStep: (step: number) => void;
  updateCollisionCylinder: (collisionCylinder: CollisionCylinder, mesh: THREE.Mesh) => void;
  updateCollisionMap: (collisionParam: CollisionParam) => void;
  refreshMap: () => void;
  step: number;
  getMapKey: (position: THREE.Vector2) => string;
  getPositionMap: (position: THREE.Vector2) => THREE.Vector2;
  collisionsParams: CollisionParam[];
  removeCollisionParam: (collisionParam: CollisionParam) => void;
  intersectionOfCylinders: (collisionCylinder1: CollisionCylinder, collisionCylinder2: CollisionCylinder) => boolean;
  intersect: (intersectCylinder: CollisionCylinder, callback: (mesh: THREE.Mesh) => void) => void;
  collisionMap: CollisionMap;
}
