import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  ChatSidebar,
  ChatMessageGroup,
  SystemMessage,
  ChatMessageList,
  ChatInput,
  MessageAttachmentButton,
  SpawnMessageButton,
  ChatToolbarButton,
  SendMessageButton,
  EmojiPickerPopoverButton,
  ChatLengthWarning
} from "./ChatSidebar";
import { useMaintainScrollPosition } from "../misc/useMaintainScrollPosition";
import { spawnChatMessage } from "../chat-message";
import { discordBridgesForPresences } from "../../utils/phoenix-utils";
import { useIntl } from "react-intl";
import { MAX_MESSAGE_LENGTH } from "../../utils/chat-message";
import MessageDispatch from "../../message-dispatch";

type ChatMessage = {
  expired?: boolean;
  key: string;
  messageType: "avatarChanged";
  props: unknown;
  name?: string;
  sent?: boolean;
  senderSessionId?: string;
  sessionId?: string;
  body?: string;
  type:
    | "log"
    | "delete_message"
    | "chat"
    | "image"
    | "photo"
    | "video"
    | "join"
    | "entered"
    | "leave"
    | "display_name_changed"
    | "scene_changed"
    | "hub_name_changed"
    | "hub_changed";
};

// return [
//   ...messageGroups,
//   {
//     id: uniqueMessageId++,
//     timestamp: now,
//     sent,
//     sender: name,
//     senderSessionId: sessionId,
//     messages: [{ id: uniqueMessageId++, timestamp: now, ...messageProps }]
//   }
// ];

type MessageGroups = {
  id?: number;
  timestamp: number;
  sent?: boolean;
  sender?: string;
  senderSessionId?: string;
  systemMessage?: boolean;
  messages: Array<
    ChatMessage & {
      id: number;
      timestamp: number;
    }
  >;
}[];

type ChatContextType = {
  messageGroups: MessageGroups;
  sendMessage: (message: string) => void;
  unreadMessages: boolean;
  setMessagesRead?: () => void;
};

const ChatContext = createContext<ChatContextType>({ messageGroups: [], sendMessage: () => {}, unreadMessages: false });

let uniqueMessageId = 0;

const NEW_MESSAGE_GROUP_TIMEOUT = 1000 * 60;

function shouldCreateNewMessageGroup(messageGroups: MessageGroups, newMessage: ChatMessage, now: number) {
  if (messageGroups.length === 0) {
    return true;
  }

  const lastMessageGroup = messageGroups[messageGroups.length - 1];

  if (lastMessageGroup.senderSessionId !== newMessage.sessionId) {
    return true;
  }

  const lastMessage = lastMessageGroup.messages[lastMessageGroup.messages.length - 1];

  return now - lastMessage.timestamp > NEW_MESSAGE_GROUP_TIMEOUT;
}

function processChatMessage(messageGroups: MessageGroups, newMessage: ChatMessage): MessageGroups {
  const now = Date.now();
  const { name, sent, sessionId, ...messageProps } = newMessage;

  if (shouldCreateNewMessageGroup(messageGroups, newMessage, now)) {
    return [
      ...messageGroups,
      {
        id: uniqueMessageId++,
        timestamp: now,
        sent,
        sender: name,
        senderSessionId: sessionId,
        messages: [{ id: uniqueMessageId++, timestamp: now, ...messageProps }]
      }
    ];
  }

  const lastMessageGroup = messageGroups.pop();

  if (lastMessageGroup) {
    lastMessageGroup.messages = [
      ...lastMessageGroup.messages,
      { id: uniqueMessageId++, timestamp: now, ...messageProps }
    ];
  }

  // @ts-expect-error
  return [...messageGroups, { ...lastMessageGroup }];
}

// Returns the new message groups array when we receive a message.
// If the message is ignored, we return the original message group array.
function updateMessageGroups(messageGroups: MessageGroups, newMessage: ChatMessage): MessageGroups {
  switch (newMessage.type) {
    case "join":
    case "entered":
    case "leave":
    case "display_name_changed":
    case "scene_changed":
    case "hub_name_changed":
    case "hub_changed":
    case "log":
      return [
        // @ts-expect-error
        ...messageGroups,
        // @ts-expect-error
        {
          id: uniqueMessageId++,
          systemMessage: true,
          timestamp: Date.now(),
          ...newMessage
        }
      ];
    case "chat":
    case "image":
    case "photo":
    case "video":
      return processChatMessage(messageGroups, newMessage);
    default:
      return messageGroups;
  }
}

type ChatContextProviderProps = {
  messageDispatch?: MessageDispatch;
};

export const ChatContextProvider: React.FC<React.PropsWithChildren<ChatContextProviderProps>> = ({
  messageDispatch,
  children
}) => {
  const [messageGroups, setMessageGroups] = useState<MessageGroups>([]);
  const [unreadMessages, setUnreadMessages] = useState(false);

  useEffect(() => {
    function onReceiveMessage(event: CustomEvent<ChatMessage>) {
      const newMessage = event.detail;
      if (newMessage.type === "delete_message") {
        setMessageGroups(messages =>
          messages.map(el => {
            if (el.senderSessionId === newMessage.sessionId) {
              return {
                ...el,
                messages: el.messages.map(message => {
                  return { ...message, body: "<сообщение удалено>" };
                })
              };
            }
            return el;
          })
        );
        return;
      }
      setMessageGroups(messages => updateMessageGroups(messages, newMessage));
      if (
        newMessage.type === "chat" ||
        newMessage.type === "image" ||
        newMessage.type === "photo" ||
        newMessage.type === "video"
      ) {
        setUnreadMessages(true);
      }
    }

    if (messageDispatch) {
      // @ts-expect-error
      messageDispatch.addEventListener("message", onReceiveMessage);
    }

    return () => {
      if (messageDispatch) {
        // @ts-expect-error
        messageDispatch.removeEventListener("message", onReceiveMessage);
      }
    };
  }, [messageDispatch, setMessageGroups, setUnreadMessages]);

  const sendMessage = useCallback(
    (message: string) => {
      if (messageDispatch) {
        messageDispatch.dispatch(message);
      }
    },
    [messageDispatch]
  );

  const setMessagesRead = useCallback(() => {
    setUnreadMessages(false);
  }, [setUnreadMessages]);

  return (
    <ChatContext.Provider value={{ messageGroups, unreadMessages, sendMessage, setMessagesRead }}>
      {children}
    </ChatContext.Provider>
  );
};

// ChatContextProvider.propTypes = {
//   children: PropTypes.node,
//   messageDispatch: PropTypes.object
// };

type ChatSidebarContainerProps = {
  scene: any;
  canSpawnMessages: boolean;
  presences: any;
  occupantCount: number;
  inputEffect: (input: HTMLInputElement | undefined) => void;
  onClose: Function;
};

export const ChatSidebarContainer: React.FC<ChatSidebarContainerProps> = ({
  scene,
  canSpawnMessages,
  presences,
  occupantCount,
  inputEffect,
  onClose
}) => {
  const { messageGroups, sendMessage, setMessagesRead } = useContext(ChatContext);
  const [onScrollList, listRef, scrolledToBottom] = useMaintainScrollPosition(messageGroups);
  const [message, setMessage] = useState("");
  const typingTimeoutRef = useRef<NodeJS.Timeout>();
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>();

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        // @ts-expect-error
        if (e.target.value.length <= MAX_MESSAGE_LENGTH) {
          // @ts-expect-error
          sendMessage(e.target.value);
          setMessage("");
          // intentionally only doing this on "enter" press and not clicking of send button
          // @ts-expect-error
          if (e.target.value.startsWith("/")) {
            onClose();
          }
        }
      } else if (e.key === "Escape") {
        onClose();
      }
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => window.APP.hubChannel?.endTyping(), 500);
      window.APP.hubChannel?.beginTyping();
    },
    [sendMessage, setMessage, onClose]
  );

  const onSendMessage = useCallback(() => {
    sendMessage(message.substring(0, MAX_MESSAGE_LENGTH));
    setMessage("");
  }, [message, sendMessage, setMessage]);

  const onSpawnMessage = () => {
    spawnChatMessage(message);
    setMessage("");
  };

  const onUploadAttachments = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // TODO: Right now there's no way to upload files to the chat only.
      // When we add the place menu whcih will have an explicit button for uploading files,
      // should we make this attach button only upload to chat?
      for (const file of e.target.files ?? []) {
        scene.emit("add_media", file);
      }
    },
    [scene]
  );

  const onSelectEmoji = useCallback(
    ({ emoji, pickerRemainedOpen }: { emoji: any; pickerRemainedOpen: boolean }) => {
      setMessage(message => message + emoji.native);
      // If the picker remained open, avoid selecting the input so that the
      // user can keep picking emojis.
      if (!pickerRemainedOpen) inputRef.current?.select();
    },
    [setMessage, inputRef]
  );

  useEffect(() => inputEffect(inputRef.current), [inputEffect, inputRef]);

  useEffect(() => {
    if (scrolledToBottom) {
      setMessagesRead && setMessagesRead();
    }
  }, [messageGroups, scrolledToBottom, setMessagesRead]);

  const discordBridges = discordBridgesForPresences(presences);
  const discordSnippet = discordBridges.map(ch => "#" + ch).join(", ");
  let placeholder;

  if (occupantCount <= 1) {
    if (discordBridges.length === 0) {
      placeholder = intl.formatMessage({
        id: "chat-sidebar-container.input-placeholder.empty-room",
        defaultMessage: "Nobody is here yet..."
      });
    } else {
      placeholder = intl.formatMessage(
        {
          id: "chat-sidebar-container.input-placeholder.empty-room-bot",
          defaultMessage: "Send message to {discordChannels}"
        },
        { discordChannels: discordSnippet }
      );
    }
  } else {
    if (discordBridges.length === 0) {
      placeholder = intl.formatMessage(
        {
          id: "chat-sidebar-container.input-placeholder.occupants",
          defaultMessage:
            "{occupantCount, plural, one {Send message to one other...} other {Send message to {occupantCount} others...} }"
        },
        { discordChannels: discordSnippet, occupantCount: occupantCount - 1 }
      );
    } else {
      placeholder = intl.formatMessage(
        {
          id: "chat-sidebar-container.input-placeholder.occupants-and-bot",
          defaultMessage:
            "{occupantCount, plural, one {Send message to one other and {discordChannels}...} other {Send message to {occupantCount} others and {discordChannels}...} }"
        },
        { discordChannels: discordSnippet, occupantCount: occupantCount - 1 }
      );
    }
  }

  const isMobile = AFRAME.utils.device.isMobile();
  const isOverMaxLength = message.length > MAX_MESSAGE_LENGTH;

  return (
    <ChatSidebar onClose={onClose}>
      <ChatMessageList
        // @ts-expect-error
        ref={listRef}
        onScroll={onScrollList}
      >
        {messageGroups.map(({ id, systemMessage, ...rest }) => {
          if (systemMessage) {
            return <SystemMessage key={id} {...rest} />;
          } else {
            return <ChatMessageGroup key={id} {...rest} />;
          }
        })}
      </ChatMessageList>
      <ChatInput
        // @ts-expect-error
        id="chat-input"
        ref={inputRef}
        onKeyDown={onKeyDown}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
        placeholder={placeholder}
        value={message}
        isOverMaxLength={isOverMaxLength}
        warning={
          <>
            {message.length + 50 > MAX_MESSAGE_LENGTH && (
              <ChatLengthWarning messageLength={message.length} maxLength={MAX_MESSAGE_LENGTH} />
            )}
          </>
        }
        afterInput={
          <>
            {!isMobile && (
              <EmojiPickerPopoverButton
                // @ts-expect-error
                onSelectEmoji={onSelectEmoji}
              />
            )}
            {message.length === 0 && canSpawnMessages ? (
              <MessageAttachmentButton onChange={onUploadAttachments} />
            ) : (
              <SendMessageButton onClick={onSendMessage} disabled={message.length === 0 || isOverMaxLength} />
            )}
            {canSpawnMessages && (
              <SpawnMessageButton disabled={message.length === 0 || isOverMaxLength} onClick={onSpawnMessage} />
            )}
          </>
        }
      />
    </ChatSidebar>
  );
};

// ChatSidebarContainer.propTypes = {
//   canSpawnMessages: PropTypes.bool,
//   presences: PropTypes.object.isRequired,
//   occupantCount: PropTypes.number.isRequired,
//   scene: PropTypes.object.isRequired,
//   onClose: PropTypes.func.isRequired,
//   inputEffect: PropTypes.func.isRequired
// };

export function ChatToolbarButtonContainer(props: any) {
  const { unreadMessages } = useContext(ChatContext);
  return <ChatToolbarButton {...props} statusColor={unreadMessages ? "unread" : undefined} />;
}
