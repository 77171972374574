import { css } from "styled-components";
import { styled } from "styled-components";
import { landscapeMinHeight, landscapeMinHeight2, portraitMinWidth, portraitMinWidth2 } from "../../../custom/theme";

export const ToolbarButtonIconContainerStyled = styled.div``;

export const ToolbarButtonStyled = styled.button(({ theme }) => {
  return css`
    width: 40px;
    height: 40px;

    ${ToolbarButtonIconContainerStyled} {
      width: 100%;
      height: 100%;

      svg,
      img {
        width: 70%;
        height: 70%;
      }
    }

    @media ((orientation: landscape) and (min-height: ${landscapeMinHeight}px)) or ((orientation: portrait) and (min-width: ${portraitMinWidth}px)) {
      width: 46px;
      height: 46px;
    }

    @media ((orientation: landscape) and (min-height: ${landscapeMinHeight2}px)) or ((orientation: portrait) and (min-width: ${portraitMinWidth2}px)) {
      width: 64px;
      height: 64px;
    }

  `;
});
