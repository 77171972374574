import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { POSTPROCESSING } from "../../constants";

AFRAME.registerSystem("visual-effects-system", {
  init: function () {
    if (!POSTPROCESSING) return;
    const sceneEl = this.sceneEl;

    const scene = sceneEl.object3D;
    const renderer = sceneEl.renderer;
    const pixelRatio = renderer.getPixelRatio();

    const size = new THREE.Vector2(1024, 1024);

    renderer.setSize(window.innerWidth, window.innerHeight);

    const camera = sceneEl.camera;

    const composer = new EffectComposer(renderer);

    this.renderPass = new RenderPass(scene, camera);
    composer.addPass(this.renderPass);

    this.bloomPass = new UnrealBloomPass(size, 2, 1, 0.5);
    composer.addPass(this.bloomPass);

    this.FXAAPass = new ShaderPass(FXAAShader);
    this.FXAAPass.material.uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio);
    this.FXAAPass.material.uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio);
    composer.addPass(this.FXAAPass);

    // this.pixelReadPass = new PixelReadPass(400, 300, 1, 1, false);
    // composer.addPass(this.pixelReadPass);

    this.renderer = renderer;
    this.composer = composer;
    sceneEl.composer = composer;

    if (sceneEl.hasLoaded) {
      this.modifyMaterials();
    } else {
      sceneEl.addEventListener("loaded", this.init.bind(this));
      sceneEl.addEventListener("rendererresize", this.resize.bind(this));
    }
  },
  resize: function () {
    this.composer.setSize(window.innerWidth, window.innerHeight);
  },
  pause() {
    this.el.sceneEl.removeEventListener("loaded", this.init);
    this.el.sceneEl.removeEventListener("rendererresize", this.resize);
  },
  modifyMaterials() {
    if (!document.getElementById("environment-scene")) return;
    document
      .getElementById("environment-scene")
      .addEventListener("model-loaded", ({ detail: { model: mainStage } }) => {
        mainStage.traverse(o => {
          if (o.material) {
            if (o.material.map) {
              o.material.map.encoding = THREE.LinearEncoding;
              o.material.map.magFilter = THREE.LinearFilter;
              o.material.map.minFilter = THREE.LinearFilter;
            }
            o.material.toneMapped = false;
            // if (o.material.transparent === true) {
            //   o.renderOrder = 2000;
            // }
            o.material.needsUpdate = true;
          }
        });
      });
  },
});
