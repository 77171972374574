import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Toolbar.scss";
import styleUtils from "../styles/style-utils.scss";
import { ToolbarContentStyled, ToolbarStyled } from "./Toolbar/styles";

type ToolbarProps = {
  className?: string,
  left: JSX.Element
  center: JSX.Element
  right: JSX.Element
  // hideLeft?: string,
  // hideRight?: string
}

export function Toolbar({ className, left, center, right, ...rest }: ToolbarProps) {
  return (
    <ToolbarStyled className={classNames(styles.toolbar, className)} {...rest}>
      <ToolbarContentStyled className={classNames(styles.content, styles.leftContent, styleUtils.showLg)}>{left}</ToolbarContentStyled>
      <ToolbarContentStyled className={classNames(styles.content, styles.centerContent)}>{center}</ToolbarContentStyled>
      <ToolbarContentStyled className={classNames(styles.content, styles.rightContent, styleUtils.showLg)}>{right}</ToolbarContentStyled>
    </ToolbarStyled>
  );
}
