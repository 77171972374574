import { useEffect } from "react";
import { AElement } from "aframe";

export const RoboHands: React.FC = () => {
  //Изначально запускается только одна анимация
  useEffect(() => {
    const element = document.querySelector(".Arms") as AElement;
    if (element) {
      const animation = element?.components["loop-animation"];
      if (animation) {
        animation.currentActions[0].loop = THREE.LoopOnce;
        animation.currentActions[0].clampWhenFinished = true;
      }
    }
  }, []);

  return null;
};
