import { Object3D } from "three";

export const COLLIDABLE_MODELS = {
  cats2: {
    eventId: "cats-collision-2"
  },
  quiz: {
    eventId: "quiz-collision"
  },
  volumetricPlay: {
    eventId: "volumetric-play"
  },
  portal: {
    eventId: "portal"
  }
} as const;

// Эвент входа в коллизию объекта
export const COLLIDABLE_ENTER_EVENT = "inspect-collider-enter";
export type COLLIDABLE_ENTER_EVENT_DETAIL = { mesh: Object3D };

// Эвент выхода из коллизии объекта
export const COLLIDABLE_LEAVE_EVENT = "inspect-collider-leave";
