const componentName = "spoke-mesh-fireflies"; // имя создаваемого компонента

const activationName = "applyMeshFireflies"; // атрибут объекта в EventID, по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  size: 1.0,
  freq: 1.0,
  speed: 1.0,
  add: "false",
  hide: "false",
};

export function spokeMeshFireflies(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.meshes = tHelper.getMeshesWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.meshes);

    const wPos = tHelper.meshesToVertices(this.meshes, false);

    // --------------------------------------

    for (let m = 0; m < this.meshes?.length; m++) {
      const planeMat = new THREE.ShaderMaterial({
        alphaTest: 0.6,
        depthWrite: false,
        depthTest: true,
        transparent: true,
        // side: THREE.DoubleSide,
        blending: this.params[m].add == "true" ? THREE.AdditiveBlending : THREE.NormalBlending,
      });

      patchVspFirefly(planeMat, this.params[m].color, this.params[m].freq, this.params[m].speed);

      const amount = wPos[m].length / 3;

      const size = (this.params[m].size || defaultParams.size) * 3.0;

      const planeGeom = new THREE.PlaneGeometry(size, size);
      const planes = new THREE.InstancedMesh(planeGeom, planeMat, amount);
      const dummy = new THREE.Object3D();

      const quat = new THREE.Quaternion();
      this.meshes[m].getWorldQuaternion(quat);

      for (let i = 0; i < wPos[m].length; i += 3) {
        dummy.position.x = wPos[m][i];
        dummy.position.y = wPos[m][i + 1] + size * 0.5 + size * (Math.random() - 0.5);
        dummy.position.z = wPos[m][i + 2];

        dummy.setRotationFromQuaternion(quat.clone().invert());

        dummy.updateMatrix();
        planes.setMatrixAt(i / 3, dummy.matrix);
      }

      if (this.params[m].hide === "true") {
        this.meshes[m].material.visible = false;
      }

      this.meshes[m].el.setObject3D("mesh", planes);
    }

    // -----------------------------------------------------

    function patchVspFirefly(
      mat,
      ucolor = defaultParams.color,
      freq = defaultParams.freq,
      speed = defaultParams.speed,
    ) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec3 vpz;   // varying position of object center
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * instanceMatrix * vec4(position, 1.0)).xyz;               
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        // vnc = normalize(normalMatrix * normal);      
        vpz = ( modelMatrix * instanceMatrix * vec4(0.0,0.0,0.0,1.0) ).xyz;                     
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.speed = { value: speed };
        shader.uniforms.mouse = { value: [0, 0] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
      }

      const shaderUniforms = `
        uniform float time, freq, speed;
        uniform vec2 mouse;
        uniform vec3 ucolor;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
    
        /* Cartesian to Polar */
        vec2 xy2md( vec2 xy ) {
          return vec2( 
            sqrt( pow(xy.x,2.0) + pow(xy.y,2.0) ) ,
            atan(xy.y,xy.x) ); }
    
        mat4 rotation3d(vec3 axis, float angle) {
          axis = normalize(axis);
          float s = sin(angle);
          float c = cos(angle);
          float oc = 1.0 - c;
          return mat4(
            oc * axis.x * axis.x + c,           
            oc * axis.x * axis.y - axis.z * s,  
            oc * axis.z * axis.x + axis.y * s,  0.0,
            oc * axis.x * axis.y + axis.z * s,  
            oc * axis.y * axis.y + c,          
            oc * axis.y * axis.z - axis.x * s,  0.0,
            oc * axis.z * axis.x - axis.y * s,  
            oc * axis.y * axis.z + axis.x * s,  
            oc * axis.z * axis.z + c,           
            0.0,0.0,0.0,0.0,1.0
          ); }
    
      `;

      mat.onBeforeCompile = shader => {
        // console.log('Input Material Data:', [ shader, shader.vertexShader, shader.fragmentShader ]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        const shaderVert = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
            ${shaderVaryingsSet}
            ${basicVertexMain}
    
            vec3  pos = position;
            vec3  center = (modelMatrix * instanceMatrix * vec4(0.0,0.0,0.0,1.0)).xyz;
            vec3  obj2cam = normalize( cameraPosition - center ); 
    
            float angleXZ = atan(obj2cam.z, obj2cam.x);
            vec3  dirUp = vec3(0.0,1.0,0.0) * mat3(transpose((modelMatrix * instanceMatrix)));
                  pos = (vec4(pos,1.0) * rotation3d(vec3(dirUp), -angleXZ+0.5*ONE_PI)).xyz;
    
            gl_Position = projectionMatrix * viewMatrix * modelMatrix * instanceMatrix * vec4(pos, 1.0);
    
          }
    
        `;

        const shaderFrag = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicFragmentDef}
    
          void main() {
            ${basicFragmentMain}
    
            vec3  col = ucolor;
            vec2  uv = vuv-0.5; 
            float temp = time * speed;
    
            vec3  posz = vec3(0.2)+0.8*fract(vpz.xyz*11.73);
                  uv.y += 0.3*msin(temp*posz.y);
                  uv.x += 0.3*msin(temp*posz.x);
            
            float size = 0.1+(mcos(freq*temp*posz.z*10.0)*0.5+0.5);
    
            float alpha = 0.01/dot(uv,uv)*0.02*size;

            gl_FragColor = vec4(col,alpha);
            
          }
    
        `;

        initUniforms(shader);

        mat.userData.shader = shader;

        shader.vertexShader = shaderVert;
        shader.fragmentShader = shaderFrag;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.meshes?.length; i++) {
      const target = this.meshes[i].el.object3D.children[1];
      if (!target.uniforms) {
        target.uniforms =
          target.material?.userData?.shader?.uniforms || target.children[0]?.material?.userData?.shader?.uniforms;
        return;
      }

      target.uniforms.time.value = performance.now() / 1000;
      // target.uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
