//#dream_video_id=123123&&dream_video_pin=333111
import configs from "../../../../utils/configs";

export const AutorizationData = {
  defaultUrl: ``,
  prefixUrlS3: `https://${configs.RETICULUM_SERVER}/videos/meta/`,
};

export const VideoAttribute = {
  animate: false,
  contentSubtype: null,
  contentType: null,
  fileId: "",
  fileIsOwned: true,
  fitToBox: true,
  linkedEl: null,
  moveTheParentNotTheMesh: true,
  playSoundEffect: true,
  resolve: true,
  version: 1,
};

export const MediaOptions = {
  projection: "flat",
  videoPaused: false,
  loop: true,
  hidePlaybackControls: true,
};
