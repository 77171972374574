const componentName = "spoke-obj-ticker"; // имя создаваемого компонента

const activationName = "applyObjTicker"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  speed: 1,
  freq: 1,
  text: "ТЕКСТ БЕГУЩЕЙ СТРОКИ",
};

export function spokeObjTicker(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    for (let i = 0; i < this.objects?.length; i++) {
      const res = 256;

      const canvas = document.createElement("canvas");
      canvas.width = res;
      canvas.height = res * 0.125;

      const fontsize = canvas.width / 21;

      const texture = new THREE.Texture(canvas);

      const ctx = canvas.getContext("2d");
      const x = canvas.width / 2;
      const y = canvas.height / 2;

      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.font = `${fontsize}px 'SB Sans Display'`;

      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const line = this.params[i].text || defaultParams.text; // `● ЦЕНТРАЛЬНО-ЧЕРНОЗЕМНЫЙ БАНК`;
      ctx.fillText(line, x, y);

      // ctx.strokeStyle = 'white';
      // ctx.lineWidth = 1;

      // const lineheight = fontsize;
      // const lines = `ЦЕНТРАЛЬНО-ЧЕРНОЗЕМНЫЙ БАНК`.split('\n');
      // for (let i = 0; i < lines.length; i++) {
      // ctx.strokeText(lines[i], x, y - (0.5 * (lines.length - 1) * lineheight) + (i * lineheight));
      // ctx.fillText(lines[i], x, y - (0.5 * (lines.length - 1) * lineheight) + (i * lineheight));
      // }

      texture.needsUpdate = true;
      texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;

      texture.repeat.set(1, 1);
      texture.offset.set(0, 0);

      const textmesh = new THREE.Mesh(
        new THREE.CylinderGeometry(0.55, 0.55, 1, 16, 2, true),
        patchText(
          new THREE.MeshBasicMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            blending: THREE.AdditiveBlending,
            map: texture,
            // side: THREE.DoubleSide,
          }),
          this.params[i].color,
          this.params[i].speed,
        ),
      );

      // --------------------------------------------------------

      const ringmesh = new THREE.Mesh(
        new THREE.CylinderGeometry(0.5, 0.5, 1.0, 16, 2, true),
        patchRing(
          new THREE.MeshBasicMaterial({
            alphaTest: 0.6,
            // depthWrite: false,
            depthTest: true,
            transparent: true,
            // blending: THREE.AdditiveBlending,
            side: THREE.DoubleSide,
          }),
          this.params[i].color,
          this.params[i].freq,
        ),
      );

      const group = new THREE.Group();
      group.add(ringmesh);
      group.add(textmesh);

      this.objects[i].parent.el.removeObject3D("object3d");
      this.objects[i].el.setObject3D("mesh", group);
    }

    function patchRing(mat, ucolor = defaultParams.color, freq = defaultParams.freq) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        vnc = normalize(normalMatrix * normal);  
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.freq = { value: freq };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
      }

      const shaderUniforms = `
        uniform float time, freq;
        uniform vec2 mouse;
        uniform vec3 ucolor;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
                
        vec4  draw() {

          vec3  col = ucolor;
                col += abs(vnc.x*vnc.x);


          float alpha = abs(vnc.x*vnc.x);

          alpha *= cos(vpl.y*50.0)*0.5+0.5;
          alpha *= 0.25 + 0.5 * (sin(time*1.5*freq)*0.5+0.5);
  
          return vec4(col, alpha);	

        }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log("Input Material Data:", [shader, shader.vertexShader, shader.fragmentShader]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
          
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        ${basicFragmentDef}
  
        void main() {

          vec4  draw = draw();

          #include <clipping_planes_fragment>
          vec4 diffuseColor = vec4( diffuse, opacity );
          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <specularmap_fragment>
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          #ifdef USE_LIGHTMAP
            vec4 lightMapTexel = texture2D( lightMap, vUv2 );
            reflectedLight.indirectDiffuse += lightMapTexel.rgb * lightMapIntensity * RECIPROCAL_PI;
          #else
            reflectedLight.indirectDiffuse += vec3( 1.0 );
          #endif
          #include <aomap_fragment>
          reflectedLight.indirectDiffuse *= diffuseColor.rgb;

          vec3 outgoingLight = draw.rgb;

          #include <envmap_fragment>
          #include <output_fragment>
          // #include <tonemapping_fragment>
          // #include <encodings_fragment>
          #include <fog_fragment>
          #include <premultiplied_alpha_fragment>
          #include <dithering_fragment>

          gl_FragColor.a = draw.a;
        
        }
  
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
        mat.needsUpdate = true;
      };

      return mat;
    }

    function patchText(mat, ucolor = defaultParams.color, speed = defaultParams.speed) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        // vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        // vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        // vnc = normalize(normalMatrix * normal);  
        // vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.time = { value: 0 };
        shader.uniforms.speed = { value: speed };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
      }

      const shaderUniforms = `
        uniform float time, speed;
        uniform vec2 mouse;
        uniform vec3 ucolor;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))
                
        vec4  draw() {

          vec3  col = vec3(0.0); // texture2D(map, vpl.xy).rgb;
          float alpha = 1.0;
          return vec4(col, alpha);	
        }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log("Input Material Data:", [shader, shader.vertexShader, shader.fragmentShader]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
          
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        ${basicFragmentDef}
  
        void main() {

          #include <clipping_planes_fragment>
          vec4 diffuseColor = vec4( diffuse, opacity );
          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <specularmap_fragment>
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          #ifdef USE_LIGHTMAP
            vec4 lightMapTexel = texture2D( lightMap, vUv2 );
            reflectedLight.indirectDiffuse += lightMapTexel.rgb * lightMapIntensity * RECIPROCAL_PI;
          #else
            reflectedLight.indirectDiffuse += vec3( 1.0 );
          #endif
          #include <aomap_fragment>
          reflectedLight.indirectDiffuse *= diffuseColor.rgb;
          vec3 outgoingLight = reflectedLight.indirectDiffuse;


          vec3  col = texture2D(map, vuv * vec2(2.0, 0.5) + vec2(time*0.1*speed,0.25+0.05) ).rgb;
          float alpha = smoothstep(0.0,1.0, col.r); // col.r;

                col *= ucolor;

          outgoingLight = col;



          #include <envmap_fragment>
          #include <output_fragment>
          // #include <tonemapping_fragment>
          // #include <encodings_fragment>
          #include <fog_fragment>
          #include <premultiplied_alpha_fragment>
          #include <dithering_fragment>

          gl_FragColor.a = alpha;
        
        }
  
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
        mat.needsUpdate = true;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      for (let m = 0; m < target.el.object3D?.children[0]?.children.length; m++) {
        // console.log(target);

        if (!target.el.object3D?.children[0]?.children[m]?.material?.userData?.shader?.uniforms) {
          return;
        }

        target.el.object3D.children[0].children[m].material.userData.shader.uniforms.time.value =
          performance.now() / 1000;
        // target.el.object3D.children[0].children[m].material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];
      }
    }
  },
});
