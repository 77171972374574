import React from "react";
import { CollectStyled } from "./styles";

type CollectProps = {
  /**
   * Можно включать компонент только когда мы в комнате
   */
  entered: true;
};

/**
 * Корневой компонент для котов и квизов
 */
export const Collect: React.FC<CollectProps> = () => {
  if (!global.localStorage) {
    return null;
  }

  return <CollectStyled></CollectStyled>;
};
