import { styled, css } from "styled-components";
import { landscapeMinHeight, landscapeMinHeight2, portraitMinWidth, portraitMinWidth2 } from "../../../custom/theme";

export const ContentMenuStyled = styled.div(
  ({ theme }) => css`
    gap: 4px;
    top: 10px;

    /**
      В некоторых проектах (например, в метапарке) в шапке есть третья плашка квеста,
      из-за чего кнопки не помещаются.
    */
    /* @media (orientation: portrait) and (max-width: ${theme.breakpoints.xs + 30}px) { */
    @media (orientation: portrait) and (max-width: ${theme.breakpoints.sm}px) {
      top: 70px;
    }

    @media ((orientation: landscape) and (min-height: ${landscapeMinHeight}px)) or ((orientation: portrait) and (min-width: ${portraitMinWidth}px)) {
      gap: 6px;
    }

    @media ((orientation: landscape) and (min-height: ${landscapeMinHeight2}px)) or ((orientation: portrait) and (min-width: ${portraitMinWidth2}px)) {
      gap: 12px;
    }
  `
);
