/* eslint-disable @calm/react-intl/missing-formatted-message */
import React from "react";
import className from "classnames";
import PropTypes from "prop-types";
// import { joinChildren } from "../misc/joinChildren";
import styles from "./ContentMenu.scss";
import { ReactComponent as ObjectsIcon } from "../icons/Objects.svg";
import { ReactComponent as PeopleIcon } from "../icons/Guests.svg";
import { FormattedMessage } from "react-intl";
import { ToolbarButton } from "../input/ToolbarButton";

import { ContentMenuStyled } from "./ContentMenu/styles";

export function ContentMenuButton({ active, children, ...props }) {
  return (
    <button className={className(styles.contentMenuButton, { [styles.active]: active })} {...props}>
      {children}
    </button>
  );
}

ContentMenuButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

export function ECSDebugMenuButton(props) {
  return (
    <ContentMenuButton {...props}>
      <ObjectsIcon />
      <span>
        <FormattedMessage id="content-menu.ecs-debug-menu-button" defaultMessage="ECS Debug" />
      </span>
    </ContentMenuButton>
  );
}

export function ObjectsMenuButton(props) {
  return (
    <ContentMenuButton {...props}>
      <ObjectsIcon />
      <span>
        <FormattedMessage id="content-menu.objects-menu-button" defaultMessage="Objects" />
      </span>
    </ContentMenuButton>
  );
}

export function PeopleMenuButton({ active, ...props }) {
  // return (
  //   <ContentMenuButton {...props}>
  //     <PeopleIcon />

  //   </ContentMenuButton>
  // );
  return (
    <ToolbarButton
      className={className(styles.contentMenuButton, { [styles.active]: active })}
      {...props}
      icon={<PeopleIcon />}
    />
  );
}
PeopleMenuButton.propTypes = {
  presencecount: PropTypes.number,
};

export function ContentMenu({ children, ...other }) {
  return (
    <ContentMenuStyled className={styles.contentMenu} {...other}>
      {/* {joinChildren(children, () => (
        <div className={styles.separator} />
      ))} */}
      {children}
    </ContentMenuStyled>
  );
}

ContentMenu.propTypes = {
  children: PropTypes.node,
};
