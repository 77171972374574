import {
  Color,
  DepthTexture,
  Matrix4,
  Mesh,
  OrthographicCamera,
  PerspectiveCamera,
  Plane,
  ShaderMaterial,
  UniformsUtils,
  UnsignedShortType,
  Vector3,
  Vector4,
  WebGLRenderTarget,
} from "three";
import { Layers } from "../../../components/layers";

// import { KawaseBlurPass } from "./add-postprocessing";
import { KawaseBlurPass } from "postprocessing";

class Reflector extends Mesh {
  constructor(geometry, renderer, params) {
    super(geometry);

    this.type = "Reflector";

    const scope = this;

    params = params || {};

    const alpha = params.alpha || 1;
    const textureWidth = params.textureWidth || 512;
    const textureHeight = params.textureHeight || 512;
    const blurWidth = params.blurWidth || textureWidth;
    const blurHeight = params.blurHeight || textureHeight;
    const blurSize = params.blurSize ?? 1; // size of kernel
    const blurAmount = params.blurAmount ?? 1; // mixing scale

    const clipBias = 0.00015;
    const shader = Reflector.ReflectorShader;

    const reflectorPlane = new Plane();
    const normal = new Vector3();
    const reflectorWorldPosition = new Vector3();
    const cameraWorldPosition = new Vector3();
    const rotationMatrix = new Matrix4();
    const lookAtPosition = new Vector3();
    const clipPlane = new Vector4();
    const viewport = new Vector4();
    const view = new Vector3();
    const target = new Vector3();
    const q = new Vector4();

    const mirrorCamera = new PerspectiveCamera();
    mirrorCamera.layers.enable(Layers.CAMERA_LAYER_THIRD_PERSON_ONLY);
    mirrorCamera.layers.enable(Layers.CAMERA_LAYER_VIDEO_TEXTURE_TARGET);
    const renderTarget = new WebGLRenderTarget(textureWidth, textureHeight);

    const material = new ShaderMaterial({
      uniforms: UniformsUtils.clone(shader.uniforms),
      fragmentShader: shader.fragmentShader,
      vertexShader: shader.vertexShader,
      transparent: true,
      // side: THREE.DoubleSide,
    });

    const blurPass = new KawaseBlurPass({
      kernelSize: blurSize,
    });

    blurPass.setSize(blurWidth, blurHeight);
    blurPass.blurMaterial.scale = blurAmount;

    material.uniforms.tDiffuse.value = renderTarget.texture;
    material.uniforms.alpha.value = alpha;

    this.material = material;

    this.onBeforeRender = function (renderer, scene, camera) {
      reflectorWorldPosition.setFromMatrixPosition(scope.matrixWorld);
      cameraWorldPosition.setFromMatrixPosition(camera.matrixWorld);

      normal.set(0, 0, 1); // нормаль смотрит вперед
      rotationMatrix.extractRotation(scope.matrixWorld); // берем угол поворота рефлектора к миру
      normal.applyMatrix4(rotationMatrix); // и поворачиваем на него нормаль, теперь нормаль перпенддикулярна плоскости рефлектора

      view.subVectors(reflectorWorldPosition, cameraWorldPosition); // берем луч от камеры к рефлеткору
      if (view.dot(normal) > 0) return; // Avoid rendering when reflector is facing away

      view.reflect(normal); // отражаем его по нормали
      view.negate(); // меняем направление на противоположное
      view.add(reflectorWorldPosition); // помещаем к рефлектору

      lookAtPosition.set(0, 0, -100); // взгляд смотрит назад
      rotationMatrix.extractRotation(camera.matrixWorld); // берем угол поворота камеры
      lookAtPosition.applyMatrix4(rotationMatrix); // и поворачиваем на него взгляд
      lookAtPosition.add(cameraWorldPosition); // помещаем взгляд на камеру

      target.subVectors(reflectorWorldPosition, lookAtPosition);
      target.reflect(normal).negate();
      target.add(reflectorWorldPosition);

      mirrorCamera.position.copy(view);
      mirrorCamera.up.set(0, 1, 0);
      mirrorCamera.up.applyMatrix4(rotationMatrix);
      mirrorCamera.up.reflect(normal);
      mirrorCamera.lookAt(target);

      mirrorCamera.far = camera.far; // Used in WebGLBackground

      mirrorCamera.updateMatrixWorld();
      mirrorCamera.projectionMatrix.copy(camera.projectionMatrix);

      // Now update projection matrix with new clip plane, implementing code from: http://www.terathon.com/code/oblique.html
      // Paper explaining this technique: http://www.terathon.com/lengyel/Lengyel-Oblique.pdf
      reflectorPlane.setFromNormalAndCoplanarPoint(normal, reflectorWorldPosition);
      reflectorPlane.applyMatrix4(mirrorCamera.matrixWorldInverse);

      clipPlane.set(reflectorPlane.normal.x, reflectorPlane.normal.y, reflectorPlane.normal.z, reflectorPlane.constant);

      const projectionMatrix = mirrorCamera.projectionMatrix;

      q.x = (Math.sign(clipPlane.x) + projectionMatrix.elements[8]) / projectionMatrix.elements[0];
      q.y = (Math.sign(clipPlane.y) + projectionMatrix.elements[9]) / projectionMatrix.elements[5];
      q.z = -1.0;
      q.w = (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14];

      clipPlane.multiplyScalar(2.0 / clipPlane.dot(q)); // Calculate the scaled plane vector

      projectionMatrix.elements[2] = clipPlane.x; // Replacing the third row of the projection matrix
      projectionMatrix.elements[6] = clipPlane.y;
      projectionMatrix.elements[10] = clipPlane.z + 1.0 - clipBias;
      projectionMatrix.elements[14] = clipPlane.w;

      // Render

      renderTarget.texture.encoding = renderer.outputEncoding;

      scope.visible = false;

      const currentRenderTarget = renderer.getRenderTarget();

      const currentXrEnabled = renderer.xr.enabled;
      const currentShadowAutoUpdate = renderer.shadowMap.autoUpdate;

      renderer.xr.enabled = false; // Avoid camera modification
      renderer.shadowMap.autoUpdate = false; // Avoid re-computing shadows

      renderer.setRenderTarget(renderTarget);

      renderer.state.buffers.depth.setMask(true); // make sure the depth buffer is writable so it can be properly cleared, see #18897

      APP.scene.systems["fx-system"].allowRender = false;

      if (renderer.autoClear === false) renderer.clear();
      renderer.render(scene, mirrorCamera);

      if (blurSize >= 1) blurPass.render(renderer, renderTarget, renderTarget);

      renderer.xr.enabled = currentXrEnabled;
      renderer.shadowMap.autoUpdate = currentShadowAutoUpdate;

      renderer.setRenderTarget(currentRenderTarget);
      // Restore viewport

      APP.scene.systems["fx-system"].allowRender = true;

      const bounds = camera.bounds;

      if (bounds !== undefined) {
        const size = renderer.getSize();
        const pixelRatio = renderer.getPixelRatio();

        viewport.x = bounds.x * size.width * pixelRatio;
        viewport.y = bounds.y * size.height * pixelRatio;
        viewport.z = bounds.z * size.width * pixelRatio;
        viewport.w = bounds.w * size.height * pixelRatio;

        renderer.state.viewport(viewport);
      }

      scope.visible = true;
    };

    this.getRenderTarget = function () {
      return renderTarget;
    };

    this.dispose = function () {
      renderTarget.dispose();
      scope.material.dispose();
    };
  }
}

const shaderVaryings = `
varying vec2 vuv;   // varying texture coordinates
varying vec3 vpl;   // varying position local
varying vec3 vpc;   // varying position to camera
varying vec3 vpw;   // varying position to world
varying vec3 vnl;   // varying normals local
varying vec3 vnc;   // varying normals to camera
varying vec3 vnw;   // varying normals to world
varying vec4 vps;   //
`;

const shaderVaryingsSet = `
vuv = uv;
vpl = position;
vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;
// vpw = (modelMatrix * vec4(position, 1.0)).xyz;
// vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);
// vnl = normal;
// vnc = normalize(normalMatrix * normal);
vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );      
`;

Reflector.ReflectorShader = {
  uniforms: {
    tDiffuse: { type: "t", value: null },
    mouse: { value: [0, 0] },
    frc: { value: 0 },
    noise: { value: 0 },
    alpha: { value: 1 },
  },

  vertexShader: `
    ${shaderVaryings}

    void main() {
      ${shaderVaryingsSet}
    	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `,

  fragmentShader: `

    uniform vec2 mouse;
    uniform float frc, alpha;
    uniform sampler2D tDiffuse;
    uniform sampler2D noise;

    ${shaderVaryings}

    #define ONE_PI 3.14159265359             
    #define TWO_PI 6.28318530718

    #define msin(x) sin(mod(x,TWO_PI))
    #define mcos(x) cos(mod(x,TWO_PI))

    #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

    void main() {

      vec2  uvScreen = vps.xy / vps.w * 0.5 + 0.5;
            uvScreen.x = 1.0-uvScreen.x;

      vec3  col = texture2D(tDiffuse, uvScreen).rgb;

      // col += 0.2;

      gl_FragColor = vec4(col,alpha);

      #include <tonemapping_fragment>
      #include <encodings_fragment>

    }
  `,
};

export { Reflector };
