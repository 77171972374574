import orange from "../../assets/custom/textures/orange.jpg";
import darkpurple from "../../assets/custom/textures/darkpurple.jpg";
import purple from "../../assets/custom/textures/purple.jpg";
import glossy from "../../assets/custom/textures/glossy.jpg";
import exr from "../../assets/custom/env/venice_sunset_1k.jpg";
import { MeshBasicMaterial, MeshStandardMaterial, PMREMGenerator } from "three";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader";
import { ENVMAP } from "../../constants";

AFRAME.registerComponent("custom-materials", {
  init() {
    if (this.el.object3D) {
      this.loadAttempt();
    }
    this.el.addEventListener("model-loaded", this.loadAttempt.bind(this));
  },
  loadAttempt() {
    this.loadTextures()
      .then(this.applyMaterials.bind(this))
      .catch(reason => console.log(reason));
  },
  async loadTextures() {
    const textureLoader = new THREE.TextureLoader();
    const exrLoader = new EXRLoader();
    const [envTexture, DarkPurple, OrangeFresnel, PurpleFresnel, Glossy] = await Promise.all([
      exrLoader.loadAsync(exr),
      textureLoader.loadAsync(darkpurple),
      textureLoader.loadAsync(orange),
      textureLoader.loadAsync(purple),
      textureLoader.loadAsync(glossy)
    ]);
    const pmremGenerator = new PMREMGenerator(this.el.sceneEl.renderer);
    envTexture.mapping = THREE.EquirectangularReflectionMapping;
    this.envMap = pmremGenerator.fromEquirectangular(envTexture).texture;
    this.matCapTextures = {
      DarkPurple_MAT: DarkPurple,
      OrangeFresnel_MAT: OrangeFresnel,
      PurpleFresnel_MAT: PurpleFresnel,
      Glossy_MAT: Glossy
    };
  },
  applyMaterials() {
    if (ENVMAP) this.el.sceneEl.environment = this.envMap;
    this.el.object3D.traverse(o => {
      if (o.material) {
        switch (o.material.name) {
          case "DarkPurple_MAT":
          case "OrangeFresnel_MAT":
          case "PurpleFresnel_MAT":
          case "Glossy_MAT":
            o.material = new THREE.MeshMatcapMaterial({ matcap: this.matCapTextures[o.material.name] });
            break;
          case "GlossyMasked_MAT":
            if (o.material.isMeshPhysicalMaterial) {
              const prevMaterial = o.material;
              o.material = new MeshStandardMaterial();
              MeshBasicMaterial.prototype.copy.call(o.material, prevMaterial);
            }
            o.material.metalness = 0.9;
            o.material.toneMapped = false;
            break;
          default:
            if (o.material.isMeshPhysicalMaterial) {
              const prevMaterial = o.material;
              o.material = new MeshStandardMaterial();
              MeshBasicMaterial.prototype.copy.call(o.material, prevMaterial);
            }
            o.material.metalness = 1;
            o.material.roughness = 0.2;
            if (ENVMAP) o.material.envMap = this.envMap;
            o.material.toneMapped = true;
        }
        o.material.needsUpdate = true;
      }
    });
  }
});

AFRAME.GLTFModelPlus.registerComponent("custom-materials", "custom-materials");
