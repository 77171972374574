import {
  CAMERA_MODE_FIRST_PERSON
  // CAMERA_MODE_SCENE_PREVIEW,
  // CAMERA_MODE_THIRD_PERSON_VIEW
} from "../../../systems/camera-system";
import { CameraTool } from "../camera-tool";
import { renderAsEntity } from "../../../utils/jsx-entity";
// import watermarkImage from "../../../assets/custom/EdutoriaLogo.png";
import watermarkImage from "../../../assets/custom/blockT-logo.png";
import { textureLoader } from "../../../utils/media-utils";
import { Layers } from "../../../components/layers";
import { detect, detectOS } from "detect-browser";

const DEBUG_BBAA_COLOR = 0xff00ff;

const debugMaterial = new THREE.MeshBasicMaterial({
  color: DEBUG_BBAA_COLOR,
  transparent: true,
  opacity: 0.25,
  side: THREE.DoubleSide
});
// const tmpPos = new THREE.Vector3();
// const bbaa = new THREE.Box3();

AFRAME.registerComponent("photoshoot", {
  schema: {
    enabled: { default: true },
    debuggable: { default: false }
  },

  init() {
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    if (this.data.debuggable) {
      this.helper = new THREE.CameraHelper(camera);
      this.el.object3D.add(this.helper);
    }
    this.el.className = "photoshoot";
    this.el.setAttribute("tone-mapped", "enabled:false");
    const eid = renderAsEntity(APP.world, CameraTool());
    const obj = APP.world.eid2obj.get(eid);
    // this.el.object3D.position.set(0, -4, 0);
    // this.el.object3D.rotation.set([Math.PI, 0, 0]);
    obj.traverse(item => {
      if (
        ![
          "Camera Tool",
          "Camera",
          "Camera Model",
          "Snap Menu",
          "cancelPhoto",
          "RootNode",
          "cancelPhoto Label",
          "makePhoto",
          "makePhoto Label",
          "cancelPhoto",
          "makePhoto",
          "CameraPointShoot",
          "CountDown",
          "CaptureDur"
        ].includes(item.name)
      ) {
        item.layers.disableAll();
      }
    });

    const watermark = textureLoader.load(watermarkImage);
    const material = new THREE.MeshBasicMaterial({
      map: watermark,
      transparent: true,
      toneMapped: false,
      depthTest: false,
      side: THREE.DoubleSide,
      opacity: 0.7
    });

    /**
     * Здесь надо указать размеры с учетом пропорций
     */
    const geometry = new THREE.PlaneGeometry(0.5, 0.153);

    const mesh = new THREE.Mesh(geometry, material);
    mesh.receiveShadow = false;
    mesh.position.set(-1.05, -0.65, 1);
    mesh.scale.set(1, 1, 1);
    const detectedOS = detectOS(navigator.userAgent);
    const browser = detect();
    if (["iOS"].includes(detectedOS) && ["safari", "ios"].includes(browser.name)) {
      mesh.rotation.set(Math.PI, 0, 0);
      if (navigator.userAgent.indexOf("CriOS") >= 0) {
        mesh.scale.set(-1, 1, 1);
      } else {
        mesh.scale.set(-1, -1, 1);
      }
    } else {
      mesh.rotation.set(Math.PI, Math.PI, 0);
    }
    mesh.layers.set(Layers.CAMERA_LAYER_PHOTOSHOOT);
    obj.add(mesh);

    this.menu = obj.getObjectByName("Snap Menu");
    this.el.object3D.add(obj);

    // const el = document.createElement("a-entity");
    // // el.setAttribute("pitch-yaw-rotator", true);
    // // el.setAttribute("inspect-pivot-offset-from-camera", true);
    // // el.setAttribute("set-yxz-order", true);
    // el.setAttribute("rotation", "0 180 0");
    // el.setAttribute("position", "0 0 -2");
    // this.menu.position.set(0, 0, -1);
    // el.object3D.add(this.menu);
    // window.m = this.menu;
    // this.viewingCam = document.querySelector("#avatar-pov-node"); //#avatar-pov-node #viewing-camera viewing-rig
    // // this.viewingCam.object3D.add(this.menu);
    // this.viewingCam.appendChild(el);
  },

  debug(debuggable) {
    this.data.debuggable = debuggable;
    this.helper.visible = debuggable;
  }
});

AFRAME.GLTFModelPlus.registerComponent("photoshoot-camera", "photoshoot", (el, componentName, componentData) => {
  el.setAttribute(componentName, { ...componentData });
});

AFRAME.registerComponent("photoshoot-zone", {
  schema: {
    enabled: { default: true },
    debuggable: { default: true },
    mode: { default: "FP" }
  },
  init: function () {
    const debugGeometry = new THREE.BoxGeometry();
    this.cameraMode = CAMERA_MODE_FIRST_PERSON;
    this.debugMesh = new THREE.Mesh(debugGeometry, debugMaterial);
    this.debugMesh.el = this.el.object3D.el;
    // if (this.data.debuggable) {
    //   const debugBBAA = new THREE.BoxHelper(this.debugMesh, DEBUG_BBAA_COLOR);
    //   this.el.object3D.add(debugBBAA);
    // }
    this.el.object3D.updateMatrixWorld(true);
    //this.playerEl = document.querySelector("#avatar-pov-node");
  },

  isEnabled() {
    return this.data.enabled;
  },

  debug(debuggable) {
    this.data.debuggable = debuggable;
    this.el.object3D.visible = debuggable;
  },

  // getBoundingBox: (function () {
  //   return function () {
  //     return bbaa.copy(this.debugMesh.geometry.boundingBox).applyMatrix4(this.el.object3D.matrixWorld);
  //   };
  // })(),
  //
  // contains(position) {
  //   return this.getBoundingBox().containsPoint(position);
  // },

  setCameraMode(mode) {
    this.cameraMode = mode;
    AFRAME.scenes[0].systems["hubs-systems"].cameraSystem.setMode(mode);
  },

  tick() {
    // this.playerEl.object3D.getWorldPosition(tmpPos);
    // if (this.contains(tmpPos)) {
    //   switch (this.data.mode) {
    //     case "3P":
    //       if (this.cameraMode !== CAMERA_MODE_THIRD_PERSON_VIEW) {
    //         this.setCameraMode(CAMERA_MODE_THIRD_PERSON_VIEW);
    //       }
    //       break;
    //     case "S":
    //       if (this.cameraMode !== CAMERA_MODE_SCENE_PREVIEW) {
    //         this.setCameraMode(CAMERA_MODE_SCENE_PREVIEW);
    //         this.cam = document.querySelector("[photoshoot]");
    //         // console.log(this.cam);
    //         // this.camera = document.querySelector("[camera]");
    //         // this.camera.el.object3D.position.copy(this.cam.el.object3D.position);
    //       }
    //       break;
    //   }
    // } else {
    // this.setCameraMode(CAMERA_MODE_FIRST_PERSON);
    // }
  }
});

AFRAME.GLTFModelPlus.registerComponent("photoshoot-zone", "photoshoot-zone", (el, componentName, componentData) => {
  el.setAttribute(componentName, { ...componentData });
});
