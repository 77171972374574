import React from "react";
import classNames from "classnames";
import styles from "../../../../../react-components/input/InputField.scss";
import buttonStyles from "../../../../../react-components/input/Button.scss";
import iconButtonStyles from "../../../../../react-components/input/IconButton.scss";
import { LabelTextMainAuthorizationStyles, TextMainAuthorizationStyles } from "../styles/styles";

export type InputFieldType = {
  id: string;
  label: string;
  text: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputField: React.FC<InputFieldType> = ({ id, label, text, onChange }) => {
  return (
    <div className={classNames(styles.inputField)}>
      {label && <LabelTextMainAuthorizationStyles id={id}>{label}</LabelTextMainAuthorizationStyles>}
      <div className={classNames(styles.outerWrapper, buttonStyles.inputGroup, iconButtonStyles.inputGroup)}>
        <div className={classNames(styles.inputWrapper)}>
          <TextMainAuthorizationStyles id={`${id}_text`} value={text} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

InputField.displayName = "InputField";
