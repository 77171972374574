type StoreValue = {
  preferences?: {
    globalSFXVolume?: number;
  };
};

/**
 * Устанавливаем глобальный уровень звука для звуков в SoundEffectsSystem
 */
export function updateGlobalSFXVolume(volume: number) {
  APP.store.update({
    preferences: {
      globalSFXVolume:
        (APP.store._preferences as Partial<NonNullable<StoreValue["preferences"]>>).globalSFXVolume ?? volume,
    },
  });
}
