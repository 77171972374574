import { getNextHub } from "../utils/get-next-hub";
import {
  COLLIDABLE_MODELS,
  COLLIDABLE_LEAVE_EVENT,
  COLLIDABLE_ENTER_EVENT,
  COLLIDABLE_ENTER_EVENT_DETAIL,
} from "../custom/collidable-model/constants";
import "../custom/collidable-model/triggers/portal";
import { CollectCatCollisionEventId, CollectCatCollisionEventName } from "../custom/CollectCats/interfaces";
import { AComponent } from "aframe";
import { CharacterControllerSystem } from "../systems/character-controller-system";
import { CollidableSystem, CollisionCylinder } from "../custom/collidable-model/interfaces";

const teleportUrls = new Set();
type BufferUuid = Record<string, boolean>;

interface EventInspectColliderComponentComponent extends AComponent {
  mesh: THREE.Object3D;
  checkAndSendEvent: (intersectMesh: THREE.Object3D) => void;
  checkOffCollisionAndSendEvent: () => void;
  collisionObjectsBuffer: Record<string, THREE.Mesh>;
  collisionObjectsBufferA: THREE.Mesh[];
  collisionCurrent: BufferUuid;
  enable: boolean;
  avatarCollisionCylinder: CollisionCylinder;
  collidableSystem: CollidableSystem;
  characterSystem: CharacterControllerSystem;
  callbackIntersect: (mesh: THREE.Mesh) => void;
}

const EventInspectColliderComponent: Partial<EventInspectColliderComponentComponent> = {
  mesh: new THREE.Mesh(
    new THREE.CylinderGeometry(0.5, 0.5, 3.2),
    new THREE.MeshBasicMaterial({
      visible: false,
    }),
  ),
  collisionObjectsBuffer: {},
  collisionObjectsBufferA: [],
  enable: false,
  avatarCollisionCylinder: {
    radius: 0,
    minY: 0,
    height: 0,
    maxY: 0,
    position: new THREE.Vector2(),
  },

  init() {
    this.el?.setObject3D("event-inspect-collider", this.mesh);
    this.el?.object3D?.updateMatrices();
    this.collidableSystem = APP.scene?.systems["collidable-system"];
    this.characterSystem = APP.scene?.systems["hubs-systems"]["characterController"];

    this.collidableSystem?.updateCollisionCylinder(this.avatarCollisionCylinder, this.mesh);
    this.mesh.visible = false;

    this.callbackIntersect = (mesh: THREE.Mesh) => {
      this.collisionCurrent[mesh.uuid] = true;

      if (!this.collisionObjectsBuffer[mesh.uuid]) {
        this.checkAndSendEvent(mesh);
        this.collisionObjectsBuffer[mesh.uuid] = mesh;
        this.collisionObjectsBufferA.push(mesh);
      }
    };
  },
  checkAndSendEvent(intersectMesh) {
    try {
      let mesh: typeof intersectMesh | undefined = intersectMesh;

      while (mesh) {
        if (Array.isArray(mesh)) {
          continue;
        }

        const userData = mesh.userData;

        if (userData !== null && userData !== undefined) {
          const eventId: string | undefined = userData.eventId;
          const objectId: string | undefined = userData.objectId;

          if (!eventId) {
            return;
          }

          //TODO: Event backend via sendMessage
          switch (eventId) {
            case "hub":
              global?.changeHub({ id: userData.eventData });
              break;

            case "teleport":
              {
                const eventData = userData.eventData || objectId;

                if (!eventData) {
                  console.error("Can not get eventData", eventData, userData);
                  return;
                }

                if (!teleportUrls.has(eventData)) {
                  teleportUrls.add(eventData);

                  getNextHub(eventData)
                    .then(async hubId => {
                      hubId && (await global?.changeHub({ id: hubId }));
                    })
                    .catch(console.error)
                    .finally(() => {
                      teleportUrls.delete(eventData);
                    });
                }
              }
              break;

            case CollectCatCollisionEventId:
              {
                const event = new CustomEvent(CollectCatCollisionEventName, { detail: { mesh } });
                dispatchEvent(event);
              }
              break;

            case COLLIDABLE_MODELS.cats2.eventId:
            case COLLIDABLE_MODELS.quiz.eventId:
            case COLLIDABLE_MODELS.volumetricPlay.eventId:
            case COLLIDABLE_MODELS.portal.eventId:
              {
                const event = new CustomEvent(eventId, { detail: { mesh } });
                dispatchEvent(event);
              }
              break;
            default:
              {
                const event = new CustomEvent<COLLIDABLE_ENTER_EVENT_DETAIL>(COLLIDABLE_ENTER_EVENT, {
                  detail: { mesh },
                });
                dispatchEvent(event);
              }
              break;
          }
        }

        mesh = mesh.children[0];
      }
    } catch (ex) {
      console.error(ex);
    }
  },

  tick: function () {
    /**
     * Нет смысла проверять коллизии, если аватар не двигался
     */
    if (
      !this.characterSystem?._keys?.forward &&
      !this.characterSystem?._keys?.backward &&
      !this.characterSystem?._keys?.left &&
      !this.characterSystem?._keys?.right
    ) {
      return;
    }

    this.collisionCurrent = {};
    this.collidableSystem.updateCollisionCylinder(this.avatarCollisionCylinder, this.mesh);
    this.collidableSystem.intersect(this.avatarCollisionCylinder, this.callbackIntersect);

    // Проверка что вышли из коллизии объекта
    this.checkOffCollisionAndSendEvent();
  },

  // Проверим что мы уже не в коллизии с объектом
  checkOffCollisionAndSendEvent() {
    for (let i = this.collisionObjectsBufferA.length - 1; i >= 0; i--) {
      const mesh = this.collisionObjectsBufferA[i];

      if (!this.collisionCurrent[mesh.uuid]) {
        const event = new CustomEvent(COLLIDABLE_LEAVE_EVENT, { detail: { mesh } });
        dispatchEvent(event);
        delete this.collisionObjectsBuffer[mesh.uuid];
        this.collisionObjectsBufferA.splice(i, 1);
      }
    }
  },
};

AFRAME.registerComponent("event-inspect-collider", EventInspectColliderComponent);
