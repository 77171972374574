import { skyboxTexture } from "./add-skyboxTexture.js";
import { noiseTexture } from "./add-noiseTexture-hubs.js";
import { randomTexture } from "./add-randomTexture-hubs.js";

const componentName = "spoke-obj-portal"; // имя создаваемого компонента

const activationName = "applyObjPortal"; // атрибут объекта в eventID по которому будет активироваться эффект

const defaultParams = {
  // attributes of ObjectId:
  color: "white",
  skybox: 1,
  lens: 1, // lens focal length
  type: 1,
  speed: 1,
  pixels: 1,
  cells: 1,
  dmin: 8,
  dmax: 20,
};

export function spokeObjPortal(elParent) {
  const el = document.createElement("a-entity");
  el.setAttribute(componentName, ``);
  elParent.appendChild(el);
}

AFRAME.registerComponent(componentName, {
  // multiple: true,

  schema: {},

  onSceneLoaded: function () {
    const tHelper = APP.scene.systems["fx-system"].tHelper;

    this.objects = tHelper.getObjectsWithEventID(activationName);
    this.params = tHelper.getParamsFromMeshes(this.objects);

    const noise = noiseTexture.clone();
    noise.wrapS = THREE.RepeatWrapping;
    noise.wrapT = THREE.RepeatWrapping;

    const random = randomTexture.clone();
    random.wrapS = THREE.RepeatWrapping;
    random.wrapT = THREE.RepeatWrapping;
    random.minFilter = THREE.NearestFilter;
    random.magFilter = THREE.NearestFilter;

    for (let i = 0; i < this.objects?.length; i++) {
      const skybox = skyboxTexture(this.params[i].skybox || defaultParams.skybox);
      skybox.wrapS = THREE.RepeatWrapping;
      skybox.wrapT = THREE.RepeatWrapping;
      skybox.minFilter = THREE.NearestFilter;
      skybox.magFilter = THREE.NearestFilter;

      this.mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(-1, 1),
        patchBasic(
          new THREE.MeshBasicMaterial({
            alphaTest: 0.6,
            depthWrite: false,
            depthTest: true,
            transparent: true,
            side: THREE.DoubleSide,
          }),
          this.params[i].color,
          skybox,
          this.params[i].lens,
          this.params[i].type,
          this.params[i].speed,
          this.params[i].pixels,
          this.params[i].cells,
          this.params[i].dmin,
          this.params[i].dmax,
        ),
      );

      this.objects[i].parent.el.removeObject3D("object3d");
      this.objects[i].el.setObject3D("mesh", this.mesh);
    }

    function patchBasic(
      mat,
      ucolor = defaultParams.color,
      skybox,
      lens = defaultParams.lens,
      type = defaultParams.type,
      speed = defaultParams.speed,
      pixels = defaultParams.pixels,
      cells = defaultParams.cells,
      dmin = defaultParams.dmin,
      dmax = defaultParams.dmax,
    ) {
      const shaderVaryings = `
        varying vec2 vuv;   // varying texture coordinates
        varying vec3 vpl;   // varying position local
        varying vec3 vpc;   // varying position to camera
        varying vec3 vpw;   // varying position to world
        varying vec3 vpz;
        varying vec3 vnl;   // varying normals local
        varying vec3 vnc;   // varying normals to camera
        varying vec3 vnw;   // varying normals to world
        varying vec4 vps;   // varying position screen
      `;

      const shaderVaryingsSet = `
        vuv = uv;                                                         
        // vpl = position;                                                 
        vpc = (modelViewMatrix * vec4(position, 1.0)).xyz;               
        // vpw = (modelMatrix * vec4(position, 1.0)).xyz;                    
        vpz = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;                    
        // vnw = normalize(mat3(transpose(inverse(modelMatrix))) * normal);  
        // vnl = normal;                                                     
        vnc = normalize(normalMatrix * normal);  
        vps = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      `;

      function initUniforms(shader) {
        shader.uniforms.amount = { value: 0 };
        shader.uniforms.time = { value: 0 };
        shader.uniforms.lens = { value: lens };
        shader.uniforms.speed = { value: speed };
        shader.uniforms.pixels = { value: pixels };
        shader.uniforms.cells = { value: cells };
        shader.uniforms.dmin = { value: dmin };
        shader.uniforms.dmax = { value: dmax };
        shader.uniforms.mouse = { value: [0.5, 0.5] };
        shader.uniforms.ucolor = { value: new THREE.Color(ucolor) };
        shader.uniforms.noise = { value: noise };
        shader.uniforms.portal = { value: skybox };
        shader.uniforms.aspect = { value: window.innerHeight / window.innerWidth };
        shader.uniforms.random = { value: random };
      }

      const shaderUniforms = `
        uniform float time;
        uniform float aspect, lens, speed, pixels, cells, dmin, dmax;
        uniform vec2 mouse;
        uniform vec3 ucolor;
        uniform sampler2D noise, random;
        uniform sampler2D portal;
      `;

      const shaderFunctions = `
    
        #define ONE_PI 3.14159265359             
        #define TWO_PI 6.28318530718
    
        #define msin(x) sin(mod(x,TWO_PI))
        #define mcos(x) cos(mod(x,TWO_PI))
    
        #define rot(a) mat2(cos(a), sin(a), -sin(a), cos(a))

        /* Cartesian to Polar */
        vec2 xy2md( vec2 xy ) {
          return vec2( 
            sqrt( pow(xy.x,2.0) + pow(xy.y,2.0) ) ,
            atan(xy.y,xy.x) ); }
        
        /* Polar to Cartesian */
        vec2 md2xy( vec2 md ) {
          return vec2( 
            md.x * cos(md.y) ,
            md.x * sin(md.y) ); }
    
        vec2 uv2brl( vec2 uv, float pwr ) {
          uv = md2xy(xy2md(uv - 0.5) + vec2(pwr-0.5,0.0)) + 0.5;
          return uv; }
    
        vec3 GetRayDirection(vec2 uv, vec3 ro, float z) {
          vec3  cf = normalize(-ro);
          vec3  cr = normalize( cross( vec3(0,1,0), cf ));
          vec3  cu = cross(cf,cr);
          vec3  c = ro + cf * z;
          vec3  i = c + uv.x*cr + uv.y*cu;
          vec3  rd = normalize(i - ro);
          return rd;
        }

        float hexDist(vec2 uv) {
          uv = abs(uv);
          float c = dot(uv,normalize(vec2(1.0,1.73)));
          return max(c,uv.x);
        }

        float hexGrid(vec2 uv, float size) {
          vec2  r = vec2(1.0,1.73);
                uv = uv/size;
          float stroke = 0.0+0.2
          *(sin(uv.x*0.3+sin(time*0.53)*5.0)*0.5+0.5)
          *(sin(uv.y*0.4+cos(time*0.72)*3.0)*0.5+0.5)
          ;
          float f1 = hexDist(mod(uv,r)-0.5*r);
                f1 = smoothstep(0.5-stroke,0.45-stroke,f1);
          float f2 = hexDist(mod(uv+r*0.5,r)-0.5*r);
                f2 = smoothstep(0.5-stroke,0.45-stroke,f2);
          return 1.0-max(f1,f2);
        }

        float f2slit ( float f, float lvl, float len, float smt ) { 
          return smoothstep(lvl-len*0.5-smt,lvl-len*0.5    ,f) - 
                smoothstep(lvl+len*0.5    ,lvl+len*0.5+smt,f); }


                
        vec4  draw() {

          float minDist = dmin;    
          float maxDist = dmax;    
  
          vec3  col;
          vec2  uvd = vuv - 0.5;
          float dist = dot(uvd,uvd);
  
          float optics = smoothstep(minDist,maxDist,-vpc.z);
  
          // ------------------------------------
  
          vec2  uvScreen = (vuv.xy-0.5) *vec2(-0.5,1.0); 
          vec2  uvClose = vps.xy / vps.w * 0.75 * vec2(sign(vnc.z),1.0);
                uvScreen = mix( 
                  uvClose/vec2(aspect, 1.0)*vec2(1.0,2.0)*0.3, 
                  uvScreen, 
                  clamp(-vpc.z/minDist,0.0,1.0)
                );
                uvScreen.y /= -2.0;
  
          // ------------------------------------
  
          vec2  controls = vnc.xy * sign(vnc.z);
          vec4  ANG = vec4(controls*vec2(-0.25,0.25)+0.5, 0.30, -1.0);
                ANG.x -= -0.25;
                ANG.x += 0.5 * step(0.0, vnc.z);
                ANG.z = mix(0.3*lens, 0.8*2.0, optics);
                // ANG.z -= clamp(0.002*(-vpc.z),0.0,0.12);
  
          vec2  m = ANG.xy - 0.5;
          float z = ANG.z;
                m.y = clamp(m.y,-0.49,0.49);
  
          float w = mix(-z*2.0,-z*4.0,-ANG.w);
                z *= (1.0 + (0.5/ANG.z-length(uvScreen))*w);
  
          if (ANG.w<0.0) {
            uvScreen.x = -uvScreen.x * sign(vnc.z);
            m.x += 0.5;
            m.y = -m.y;
          }
  
          float dist_m = m.y;
  
          vec3  ro = vec3(0, 0, -1);
                ro.yz *= rot(-m.y*ONE_PI);
                ro.xz *= rot(-m.x*TWO_PI);
          vec2  dist_xy = vec2(1.0, mix(TWO_PI*0.1,1.0,pow(abs(dist_m)*2.0,2.0)));
  
          vec3  rd = GetRayDirection(uvScreen*dist_xy, ro, z);
  
          float yMask = sqrt(1.0-2.0*abs(rd.y*0.5));
          vec2  uvSphere = vec2( atan(rd.x, rd.z)/ONE_PI, -rd.y)*0.5+0.5;

          float duv = smoothstep(0.25, 0.0, dot(vuv-0.5,vuv-0.5) );
  
                col = texture2D(portal, uvSphere).rgb;
                col = mix(col, col*duv+ucolor, cells * ((0.3+duv*0.2) * hexGrid(uvSphere * vec2(2.0,1.0), 0.1)) ) ;
                col *= smoothstep(0.20,0.10,dist);
  
          // -----------------------------------
  
          float alpha = smoothstep(0.25,0.15,dist);
  
          vec2  uvs;
                uvs = uv2brl(vuv, 0.0);
                uvs = xy2md(uvs-0.5);
                uvs.y -= time+uvs.x*10.0;
                uvs = md2xy(uvs);
                uvs -= time*0.1;
          vec3  spiral = texture2D(noise, uvs).rgb;
  
          vec2  uvc = (vuv-0.5)*rot(time);
          vec3  clouds = texture2D(noise, uvc).rgb;

                clouds += sin(time*3.0)*0.25+0.75; // add brightness
                clouds *= ucolor;
  
          vec3  circle = mix(spiral, clouds, dist*2.5);
                circle *= smoothstep(0.0,0.20,dist);
  
                col += circle;
  
                // col = mix (col, vec3(1.0), 0.4 * smoothstep(0.30,0.0,dist) * optics);

          if (pixels > 0.0) {

            float shift = (vpz.x+vpz.y+vpz.z)*1.2835;
            
            float fspeed = speed; //0.25 + 0.75 * texture2D(random,(vec2(vuv.x-shift,0.0))/512.0*15.0*pixels).b;
            float bspeed = speed; //0.25 + 0.75 * texture2D(random,(vec2(vuv.x-shift,0.0))/512.0*30.0*pixels).b;
  
            float rand = texture2D(random,(vuv-vec2(shift,shift+time*0.5*fspeed))/512.0*15.0*pixels).r;
                  rand = f2slit(rand, 0.5+sin(shift+time)*0.25, 0.01, 0.05);
                  rand *= 1.0-duv;
  
                  col = mix(col, vec3(1.0), rand);
                  alpha = mix(alpha, 1.0, rand);
  
                  rand = texture2D(random,(vuv-vec2(shift,shift+time*0.25*bspeed))/512.0*30.0*pixels).g;
                  rand = f2slit(rand, 0.5+sin(shift+time)*0.25, 0.01, 0.05);
  
                  alpha = max(alpha, rand*0.25);

          }

  
          return vec4(col, alpha);	

        }
          
      `;

      mat.onBeforeCompile = shader => {
        // console.log("Input Material Data:", [shader, shader.vertexShader, shader.fragmentShader]);

        const basicVertex = shader.vertexShader.split("void main() {");
        const basicVertexDef = basicVertex[0];
        const basicVertexMain = basicVertex[1].split("}")[0];

        const basicFragment = shader.fragmentShader.split("void main() {");
        const basicFragmentDef = basicFragment[0];
        const basicFragmentMain = basicFragment[1].split("}")[0];

        shader.vertexShader = `
    
          ${shaderVaryings}
          ${shaderUniforms}
          ${shaderFunctions}
          ${basicVertexDef}
    
          void main() {
    
            ${shaderVaryingsSet}
            ${basicVertexMain}

            gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
    
          }
    
        `;

        shader.fragmentShader = `
          
        ${shaderVaryings}
        ${shaderUniforms}
        ${shaderFunctions}
        ${basicFragmentDef}
  
        void main() {
  
          // $ {basicFragmentMain}

          vec4  draw = draw();

          #include <clipping_planes_fragment>
          vec4 diffuseColor = vec4( diffuse, opacity );
          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <specularmap_fragment>
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          #ifdef USE_LIGHTMAP
            vec4 lightMapTexel = texture2D( lightMap, vUv2 );
            reflectedLight.indirectDiffuse += lightMapTexel.rgb * lightMapIntensity * RECIPROCAL_PI;
          #else
            reflectedLight.indirectDiffuse += vec3( 1.0 );
          #endif
          #include <aomap_fragment>
          reflectedLight.indirectDiffuse *= diffuseColor.rgb;

          vec3 outgoingLight = draw.rgb; // reflectedLight.indirectDiffuse;

          #include <envmap_fragment>
          #include <output_fragment>
          // #include <tonemapping_fragment>
          // #include <encodings_fragment>
          #include <fog_fragment>
          #include <premultiplied_alpha_fragment>
          #include <dithering_fragment>

          gl_FragColor.a = draw.a;
        
        }
  
        `;

        initUniforms(shader);

        mat.userData.shader = shader;
        mat.needsUpdate = true;
      };

      return mat;
    }
  },

  init: function () {
    this.el.sceneEl.addEventListener("environment-scene-loaded", this.onSceneLoaded.bind(this), { once: true });
  },

  tick: function () {
    for (let i = 0; i < this.objects?.length; i++) {
      const target = this.objects[i];

      // console.log(target);

      if (!target.uniforms) {
        target.uniforms =
          target.el.object3D?.children[0]?.material?.userData?.shader?.uniforms ||
          target.el.object3D?.children[0]?.children[0]?.material?.userData?.shader?.uniforms;
        return;
      }

      target.el.object3D.children[0].material.userData.shader.uniforms.time.value = performance.now() / 1000;
      target.el.object3D.children[0].material.userData.shader.uniforms.aspect.value =
        window.innerHeight / window.innerWidth;
      // target.el.object3D.children[0].material.userData.shader.uniforms.mouse.value = [cursor.x, cursor.y];
    }
  },
});
